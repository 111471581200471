import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { authenticate, deauthenticate } from "../slices/userSlice";

export default function useToken() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  function getCurrentUser() {
    if (!token) return;
    const URL = process.env.REACT_APP_DEVISE_API_URL + "users/users";
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch(authenticate(res.data.data));
        }
      })
      .catch(function (error) {
        removeToken();
      });
  }
  const getToken = () => {
    const localStorageToken = localStorage.getItem("token");
    const sessionStorageToken = sessionStorage.getItem("token");
    const tokenString = localStorageToken
      ? localStorageToken
      : sessionStorageToken;

    const userToken = tokenString;
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    if (!token || currentUser.loggedIn === true) return;
    getCurrentUser();
  }, [token]);

  const saveToken = (userToken, remember) => {
    if (remember) {
      localStorage.setItem("token", userToken);
    } else {
      sessionStorage.setItem("token", userToken);
    }
    setToken(userToken);
  };

  const removeToken = () => {
    // export function from module
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    setToken(null);
    dispatch(deauthenticate());
  };

  return {
    setToken: saveToken,
    removeToken,
    token,
  };
}
