import SettingsSideBar from "./SettingsSideBar";
import Button from "../../components/elements/Button";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";
import NoDataModal from "./NoDataModal";

function SettingsHome(props) {
  const history = useHistory();
  const { token } = useToken();

  const [firstName, setFirstName] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [completionPercent, setCompletionPercent] = useState(0);
  const [userId, setUserId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // state for user insurances
  const [employer, setEmployer] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [planType, setPlanType] = useState("");
  const [insuranceIDNumber, setInsuranceIDNumber] = useState("");

  useEffect(() => {
    getUserInformation();
  }, []);

  useEffect(() => {
    getUserInsuranceInformation();
  }, [userId]);

  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_informations";
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        var user_information = res.data.data.attributes;
        console.log(user_information);
        setCompletionPercent(user_information.completion);
        setFirstName(user_information.first_name);
        setFullName(user_information.full_name);
        setUserId(user_information.user_id);
        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  // get user insurance information
  function getUserInsuranceInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userId;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setEmployer(
          userInformation.employer_name[0]
            ? userInformation.employer_name[0]
            : ""
        );
        setInsurancePlan(
          userInformation.issuer_legal_name[0]
            ? userInformation.issuer_legal_name[0]
            : ""
        );
        setPlanType(userInformation.plan_type ? userInformation.plan_type : "");
        setInsuranceIDNumber(
          userInformation.member_id ? userInformation.member_id : ""
        );
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const BulletList = (listItems) => {
    return (
      <div className="settings__bullet-list-container">
        {listItems.title ? <p className="body-1">{listItems.title}</p> : null}
        <ul className="settings__bullet-list">
          {listItems.items.map((item, index) => (
            <li key={index}>
              <p className="body-2">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const myProfileData = (myProfileListItems) => {
    return (
      <div className="settings__my-profile-data">
        {BulletList({ title: "What's Next:", items: myProfileListItems })}

        <div className="settings__profile-completion">
          <div className="settings__profile-completion-descripton">
            <p className="settings__profile-completion-text">
              Profile Completion
            </p>
            <p className="settings__profile-completion-percentage">
              {completionPercent + "%"}
            </p>
          </div>

          <div className="settings__profile-completion-bar">
            {" "}
            <div
              className="settings__profile-completion-bar-filled"
              style={{ width: completionPercent + "%" }}
            />
          </div>
        </div>

        <p className="body-2 mobile-hide">
          View profile information, next steps, preferences & more
        </p>
      </div>
    );
  };

  const HomeCard = (cardData, index) => {
    function goToSettingsPage(page) {
      history.push({
        pathname: "/settings/" + page,
        state: {
          userId: userId,
          employer: employer,
          planType: planType,
          insuranceIDNumber: insuranceIDNumber,
          insurancePlan: insurancePlan,
          fullName: fullName,
        },
      });
      window.scrollTo(0, 0);
    }

    return (
      <div
        className="settings__home-card"
        key={cardData.page ? cardData.page : index}
        onClick={(e) => goToSettingsPage(cardData.page)}
      >
        <p className="settings__home-card-title">{cardData.title}</p>

        {cardData.body}

        <div className="settings__home-see-more-btn">
          <Button label="See More" active={true} class="long-btn text-btn" />
        </div>
      </div>
    );
  };
  const insurancePlanData = (insurancePlanListItems) => {
    return (
      <div>
        <p>
          Your Insurance Plan: <b>{insurancePlan}</b>
        </p>
        <p>
          Your Insurance ID: <b>{insuranceIDNumber}</b>
        </p>
        {BulletList({ items: insurancePlanListItems })}
      </div>
    );
  };
  const Home = () => {
    const privacyListItems = [
      "Update password",
      "View HIPAA documents",
      "Manage devices & data",
    ];
    const notificationListItems = ["Communication", "Billing", "Handl updates"];
    const myProfileListItems = [
      "Update plan dependents",
      "Confirm your EOB",
      "Set up Billing Center preferences",
    ];
    const helpCenterListItems = [
      "FAQs",
      "Terms & conditions and privacy policy",
      "Contact information",
    ];
    const insurancePlanListItems = [
      "Learn about your plan",
      "View deductible, coverage info & more",
    ];

    var homeCardData = [
      {
        title: "My Profile",
        body: myProfileData(myProfileListItems),
        page: "my-profile",
      },
      {
        title: "Insurance plan details",
        body: insurancePlanData(insurancePlanListItems),
        page: "plan-details",
      },
      {
        title: "Privacy and security",
        body: BulletList({ items: privacyListItems }),
        page: "privacy",
      },
      {
        title: "Notifications",
        body: BulletList({ items: notificationListItems }),
        page: "notifications",
      },
      {
        title: "Help center",
        body: BulletList({ items: helpCenterListItems }),
        page: "help-center",
      },
    ];

    var homeCards = homeCardData.map((item, index) => HomeCard(item, index));

    var insuranceInfo =
      firstName != null ? (
        <div className="settings___home-insurance-info">
          <p className="body-3">
            Your Employer: <b>{employer}</b>
          </p>
          <p className="body-3">
            Insurance Plan: <b>{insurancePlan}</b>
          </p>
          <p className="body-3">
            Insurance ID: <b>{insuranceIDNumber}</b>
          </p>
        </div>
      ) : null;
    return (
      <div className="settings__home settings-page">
        {/* <NoDataModal userId={userId} token={token} show={loaded && !firstName}/> */}
        <div className="settings__home-info">
          <p className="settings__page-title">Settings</p>

          <h1>
            Hey{firstName ? " " + firstName + "," : ","} <br />
            glad you're here.
          </h1>
          {insuranceInfo}
        </div>

        {homeCards}
      </div>
    );
  };

  return (
    <SidebarLayout
      sidebar={<SettingsSideBar token={token} mobileHide={true} />}
      sidebarClasses="settings__side-bar"
      content={Home()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default SettingsHome;
