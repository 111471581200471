import React, { useState, useEffect } from "react";
import "./Onboarding.css";
import Landing from "./Landing";
import LetsGo from "./LetsGo";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import footer from "../../assets/icons/onboarding/footer.svg";
import blueWave from "../../assets/onboarding/blueWave.svg";
import minion from "../../assets/onboarding/minion.svg";
import greenWave from "../../assets/onboarding/greenWave.svg";
import bubbles from "../../assets/onboarding/bubbles.svg";
import bubblesFinal from "../../assets/onboarding/bubblesFinal.svg";
import mobileMinion from "../../assets/onboarding/mobileMinion.svg";

import useToken from "../../hooks/useToken";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import FAQAccordian from "../SignIn/FAQAccordian";

function Onboarding(...restProps) {
  const [userID, setUserID] = useState(null);
  const [currentPage, setCurrentPage] = useState("landing");
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [firstName, setFirstName] = useState("");
  const [sex, setSex] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // insurance information/step
  const [providersList, setProvidersList] = useState([]);
  const [employer, setEmployer] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [planType, setPlanType] = useState("");
  const [insuranceIDNumber, setInsuranceIDNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  // location information
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [streetAddress, setStreetAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [preferredFacilityOne, setPreferredFacilityOne] = useState("");
  const [preferredIDOne, setPreferredIDOne] = useState("");
  const [preferredFacilityTwo, setPreferredFacilityTwo] = useState("");
  const [preferredIDTwo, setPreferredIDTwo] = useState("");
  const [preferredFacilityThree, setPreferredFacilityThree] = useState("");
  const [preferredIDThree, setPreferredIDThree] = useState("");

  // notifications information
  const [userNotificationPreferences, setUserNotificationPreferences] =
    useState({
      appointment_text: false,
      appointment_email: false,
      appointment_secondary_email: false,
      billing_text: false,
      billing_email: false,
      billing_secondary_email: false,
      marketing_text: false,
      marketing_email: false,
      marketing_secondary_email: false,
      allow_location_services: false,
    });

  const { token } = useToken();

  const history = useHistory();

  // retrieve user information
  useEffect(() => {
    getUserId();
    getFacilityList();
    getInsuranceProviders();
  }, []);

  useEffect(() => {
    getUserInformation();
    getUserInsuranceInformation();
    getUserNotificationPreferences();
  }, [userID]);

  // get userID
  function getUserId() {
    if (userID == null) {
      const URL = process.env.REACT_APP_DEVISE_API_URL + "users/users";
      axios
        .get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setUserID(res.data.data.id);
          } else {
            history.push({
              pathname: "/sign_in",
            });
          }
        })
        .catch(function (error) {
          history.push({
            pathname: "/sign_in",
          });
        });
    } else {
      getUserInformation();
    }
  }
  // get facilities list
  function getFacilityList() {
    const URL = process.env.REACT_APP_API_URL + "facilities";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setFacilitiesList(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // get insurance providers
  function getInsuranceProviders() {
    const URL = process.env.REACT_APP_API_URL + "insurance_providers";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setProvidersList(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // get user info based on user id
  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setFirstName(
          userInformation.full_name
            ? userInformation.full_name.split(" ")[0]
            : ""
        );
        setDateOfBirth(
          userInformation.formatted_birthdate
            ? userInformation.formatted_birthdate
            : ""
        );
        setPersonalEmail(
          userInformation.secondary_email ? userInformation.secondary_email : ""
        );
        setPhoneNumber(
          userInformation.phone_number ? userInformation.phone_number : ""
        );
        setPreferredName(
          userInformation.preferred_name ? userInformation.preferred_name : ""
        );
        setPronouns(userInformation.pronouns ? userInformation.pronouns : "");
        setSex(userInformation.sex ? userInformation.sex : "");
        setState(userInformation.state ? userInformation.state : "");
        setStreetAddress(
          userInformation.street_address ? userInformation.street_address : ""
        );
        setZipcode(
          userInformation.zip_code.zip_codes
            ? userInformation.zip_code.zip_codes
            : ""
        );
        setPreferredFacilityOne(
          userInformation.preferred_facilities[0]?.[0]?.[0]
            ? userInformation.preferred_facilities[0][0][0]
            : ""
        );
        setPreferredIDOne(
          userInformation.preferred_facilities[0]?.[0]?.[1]
            ? userInformation.preferred_facilities[0][0][1]
            : ""
        );
        setPreferredFacilityTwo(
          userInformation.preferred_facilities[1]?.[0]?.[0]
            ? userInformation.preferred_facilities[1][0][0]
            : ""
        );
        setPreferredIDTwo(
          userInformation.preferred_facilities[1]?.[0]?.[1]
            ? userInformation.preferred_facilities[1][0][1]
            : ""
        );
        setPreferredFacilityThree(
          userInformation.preferred_facilities[2]?.[0]?.[0]
            ? userInformation.preferred_facilities[2][0][0]
            : ""
        );
        setPreferredIDThree(
          userInformation.preferred_facilities[2]?.[0]?.[1]
            ? userInformation.preferred_facilities[2][0][1]
            : ""
        );
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  // get user insurance information
  function getUserInsuranceInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setEmployer(
          userInformation.employer_name[0]
            ? userInformation.employer_name[0]
            : ""
        );
        setInsurancePlan(
          userInformation.issuer_legal_name[0]
            ? userInformation.issuer_legal_name[0]
            : ""
        );
        setPlanType(userInformation.plan_type ? userInformation.plan_type : "");
        setInsuranceIDNumber(
          userInformation.member_id ? userInformation.member_id : ""
        );
        setGroupNumber(
          userInformation.group_id ? userInformation.group_id : ""
        );
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  // get user notifications information
  function getUserNotificationPreferences() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_notification_preferences/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          const preferences = res.data.data.attributes;
          setUserNotificationPreferences({
            appointment_text: preferences.appointment_text,
            appointment_email: preferences.appointment_email,
            appointment_secondary_email:
              preferences.appointment_secondary_email,
            billing_text: preferences.billing_text,
            billing_email: preferences.billing_email,
            billing_secondary_email: preferences.billing_secondary_email,
            marketing_text: preferences.marketing_text,
            marketing_email: preferences.marketing_email,
            marketing_secondary_email: preferences.marketing_secondary_email,
            allow_location_services: preferences.allow_location_services,
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function switchPage(page) {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  }

  const Footer = (props) => {
    return props.letsGo ? (
      <>
        <div className="onboarding__landing-footer-container">
          <img className="onboarding__landing-minion" src={bubbles} />
          <img className="onboarding__landing-wave" src={greenWave} />
        </div>
        <img
          className="onboarding__landing-mobile-minion"
          src={mobileMinion}
          alt="mobile footer"
        />
      </>
    ) : props.stepFive ? (
      <div className="onboarding__landing-footer-container-stepFive">
        <img className="onboarding__landing-minion" src={bubblesFinal} />
        <img className="onboarding__landing-wave" src={greenWave} />
      </div>
    ) : (
      <div className="onboarding__landing-footer-container">
        <img className="onboarding__landing-minion" src={minion} />
        <img className="onboarding__landing-wave" src={blueWave} />
      </div>
    );
  };

  // accordian information
  const accordianInformationOne = [
    {
      title: "Why do you need my personal email?",
      body: "Once you finish setting up your account, you’ll be able to switch your default email to your personal one. We ask for it now to save you time later. 😊",
    },
    {
      title: "I already have an account.",
      body: [
        "We’re glad you’re already onboard! You can login ",
        <a className="FAQ-link" href="/sign_in">
          here.
        </a>,
      ],
    },
  ];

  const accordianInformationTwo = [
    {
      title: "Where can I find this information?",
      body: "You can find your insurance plan details on your insurance card or portal.",
    },
    {
      title: "Will Handl Health contact my insurer?",
      body: "We’ll contact your insurer if we need to confirm prices or negotiate any healthcare bills. Say goodbye to long wait times on the phone to your insurer - we’ll take it from here.",
    },
  ];

  const accordianInformationThree = [
    {
      title: "Can I update these preferences later?",
      body: "Yes! Once you’ve finished setting up your account, you can always update your preferences in your settings.",
    },
    {
      title: "What are preferred facilities?",
      body: "Preferred facilities are your top 3 healthcare facilities where you like to receive care. When you search for care on Handl Health, we’ll show you options at your preferred facilities first, when possible.",
    },
  ];

  const accordianInformationFour = [
    {
      title: "I can’t toggle on some preferences.",
      body: [
        "Darn! That means they’ve locked. Try logging out and logging back in. If you’re still experiencing the same issue, let us know ",
        <a className="FAQ-link" href="/contact">
          here.
        </a>,
      ],
    },
    {
      title: "What is your privacy policy?",
      body: [
        "We take your privacy very seriously, and there’s more than we can fit in this box! You can view our full privacy policy ",
        <a className="FAQ-link" href="#">
          here.
        </a>,
      ],
    },
  ];
  function whatPageHelper() {
    if (currentPage === "landing") {
      return (
        <Landing
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
        />
      );
    } else if (currentPage === "letsGo") {
      return (
        <LetsGo
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
        />
      );
    } else if (currentPage === "stepOne") {
      return (
        <StepOne
          token={token}
          userID={userID}
          firstName={firstName}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          preferredName={preferredName}
          setPreferredName={setPreferredName}
          personalEmail={personalEmail}
          setPersonalEmail={setPersonalEmail}
          pronouns={pronouns}
          setPronouns={setPronouns}
          sex={sex}
          setSex={setSex}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
        />
      );
    } else if (currentPage === "stepTwo") {
      return (
        <StepTwo
          token={token}
          userID={userID}
          employer={employer}
          setEmployer={setEmployer}
          insuranceIDNumber={insuranceIDNumber}
          setInsuranceIDNumber={setInsuranceIDNumber}
          insurancePlan={insurancePlan}
          setInsurancePlan={setInsurancePlan}
          planType={planType}
          setPlanType={setPlanType}
          groupNumber={groupNumber}
          setGroupNumber={setGroupNumber}
          providersList={providersList}
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
        />
      );
    } else if (currentPage === "stepThree") {
      return (
        <StepThree
          token={token}
          userID={userID}
          facilitiesList={facilitiesList}
          streetAddress={streetAddress}
          setStreetAddress={setStreetAddress}
          zipcode={zipcode}
          setZipcode={setZipcode}
          state={state}
          setState={setState}
          preferredFacilityOne={preferredFacilityOne}
          preferredIDOne={preferredIDOne}
          setPreferredIDOne={setPreferredIDOne}
          setPreferredFacilityOne={setPreferredFacilityOne}
          preferredFacilityTwo={preferredFacilityTwo}
          preferredIDTwo={preferredIDTwo}
          setPreferredIDTwo={setPreferredIDTwo}
          setPreferredFacilityTwo={setPreferredFacilityTwo}
          preferredFacilityThree={preferredFacilityThree}
          preferredIDThree={preferredIDThree}
          setPreferredFacilityThree={setPreferredFacilityThree}
          setPreferredIDThree={setPreferredIDThree}
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
          userNotificationPreferences={userNotificationPreferences}
          setUserNotificationPreferences={setUserNotificationPreferences}
        />
      );
    } else if (currentPage === "stepFour") {
      return (
        <StepFour
          token={token}
          userID={userID}
          phoneNumber={phoneNumber}
          personalEmail={personalEmail}
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
          userNotificationPreferences={userNotificationPreferences}
          setUserNotificationPreferences={setUserNotificationPreferences}
        />
      );
    } else if (currentPage === "stepFive") {
      return (
        <StepFive
          token={token}
          userID={userID}
          currentPage={currentPage}
          progressPercentage={progressPercentage}
          setProgressPercentage={setProgressPercentage}
          switchPage={switchPage}
          zipcode={zipcode}
        />
      );
    }
  }
  return (
    <>
      <SidebarLayout
        noExtraHeight
        color="blue"
        content={whatPageHelper()}
        contentClasses="onboarding__content-box"
        progressBar={
          currentPage === "stepOne" ||
          currentPage === "stepTwo" ||
          currentPage === "stepThree" ||
          currentPage === "stepFour" ||
          currentPage === "stepFive"
        }
        progressNow={progressPercentage}
      />
      {currentPage === "landing" ? (
        <Footer />
      ) : currentPage === "letsGo" ? (
        <Footer letsGo />
      ) : currentPage === "stepFive" ? (
        <Footer stepFive />
      ) : null}
      {currentPage === "stepOne" ? (
        <FAQAccordian accordianInformation={accordianInformationOne} />
      ) : currentPage === "stepTwo" ? (
        <FAQAccordian accordianInformation={accordianInformationTwo} />
      ) : currentPage === "stepThree" ? (
        <FAQAccordian accordianInformation={accordianInformationThree} />
      ) : currentPage === "stepFour" ? (
        <FAQAccordian accordianInformation={accordianInformationFour} />
      ) : null}
    </>
  );
}
export default Onboarding;
