import React, { useEffect, useState } from "react";
import "./HomeFeed.css";
import axios from "axios";
import {
  MemoizedUserNavigation,
} from "../../components/sections//Navigation";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useToken from "../../hooks/useToken";
import ProfileCompletionCard from "../../components/modules/home/ProfileCompletionCard";
import VersatileLinkCard from "../../components/modules/home/VersatileLinkCard";
import CoverageCard from "../../components/modules/home/CoverageCard";
import HandlInfoCard from "../../components/modules/home/HandlInfoCard";
import InsuranceSearchCard from "../../components/modules/home/InsuranceSearchCard";
import ArticleCard from "../../components/modules/home/ArticleCard";
import Loading from "../../components/modules/Loading";

function HomeFeed(props) {
  const [smallScreen, setSmallScreen] = useState(false);
  const [userZipcode, setUserZipcode] = useState("");
  const [preferredFacilities, setPreferredFacilities] = useState([]);
  const [fullName, setFullName] = useState("");
  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState("");
  const [nextToComplete, setNextToComplete] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { token } = useToken();
  const userID = useSelector((state) =>
    typeof state?.currentUser?.currentUser?.id === "string"
      ? state?.currentUser?.currentUser?.id
      : null
  );

  useEffect(() => {
    fetchArticleData();
  }, []);

  useEffect(() => {
    if (
      fullName === "" ||
      articles?.length < 1 ||
      profileCompletionPercentage === ""
    )
      return;
    setLoaded(true);
  }, [articles, fullName, profileCompletionPercentage]);

  useEffect(() => {
    if (userID === null) return;
    getUserInformation();
  }, [userID]);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  // get user info based on user id
  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        if (userInformation.is_onboarding_complete !== true) {
          history.push("/onboarding");
        }
        setUserZipcode(
          userInformation.zip_code ? userInformation.zip_code.zip_codes : ""
        );
        setPreferredFacilities(
          userInformation?.preferred_facilities &&
            userInformation.preferred_facilities
        );
        setFullName(userInformation?.full_name && userInformation.full_name);
        setProfileCompletionPercentage(
          userInformation?.completion && userInformation.completion
        );
        setNextToComplete(
          userInformation?.next_to_complete && userInformation.next_to_complete
        );
        console.log(res);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  async function fetchArticleData() {
    let env;
    process.env.REACT_APP_API_URL.includes("localhost")
      ? (env = "local")
      : process.env.REACT_APP_API_URL.includes("staging")
      ? (env = "staging")
      : (env = "production");
    const url = process.env.REACT_APP_API_URL + "articles";
    const request = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
      },
      params: {
        env: env,
      },
    });
    setArticles(request.data.data);
  }

  const state = {
    from: location.pathname,
    userID: userID,
    userZipcode: userZipcode,
    searchActive: true,
    smallScreen: smallScreen,
  };

  const PreferredFacilityComponent = () => {
    if (preferredFacilities?.length === 0) return;
    return (
      <div
        style={{
          textAlign: "left",
          width: "221px",
          maxWidth: "100%",
          alignSelf: "flex-start",
        }}
      >
        {preferredFacilities?.map((facility, idx) => (
          <div
            key={idx}
            style={
              idx === preferredFacilities.length - 1
                ? { marginBottom: "0" }
                : { marginBottom: "32px" }
            }
          >
            <p className="body-3">
              <span
                style={{ color: "#080707" }}
                className="fw-500 text-description"
              >
                {facility?.[0][0]}
              </span>{" "}
              <br />
              {facility?.[0][2]} <br />
              {facility?.[0][3] +
                " " +
                facility?.[0][4] +
                " " +
                facility?.[0][5]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const MobileStatsCard = (title, body, marginAdjustment = false) => {
    return (
      <Link
        to={title === "Appointments" ? "/appointments" : "/settings/my-profile"}
      >
        <div
          className="flex mobile-stats-card"
          style={marginAdjustment === true ? { marginBottom: "-10%" } : {}}
        >
          <p className="text-large dark-blue fw-500">{title}</p>
          <p className="text-medium">{body}</p>
        </div>
      </Link>
    );
  };
  return loaded === true ? (
    <>
      <MemoizedUserNavigation />
      <div className="home-container">
        <div className="grid-container homefeed">
          {smallScreen === false && (
            <div>
              <ProfileCompletionCard
                token={token}
                fullName={fullName}
                smallScreen={smallScreen}
                profileCompletionPercentage={profileCompletionPercentage}
                nextToComplete={nextToComplete}
                setProfileCompletionPercentage={setProfileCompletionPercentage}
              />
              <VersatileLinkCard
                smallScreen={smallScreen}
                title={"Ready to book?"}
                bodyText="Choose from a saved estimate or search a new service"
                buttonLabel={"find new estimate"}
                link={"/cost-estimator"}
                state={state}
              />
              <CoverageCard smallScreen={smallScreen} />
              <VersatileLinkCard
                title={"Preferred facilities"}
                renderPreferredFacilities={preferredFacilities.length > 0}
                PreferredFacilityComponent={PreferredFacilityComponent}
                bodyText={
                  "Save your favorite healthcare facilities for faster searching!"
                }
                buttonLabel={
                  preferredFacilities?.length > 0
                    ? "edit"
                    : "add preferred facility"
                }
                link={"/settings/my-profile"}
              />
            </div>
          )}
          <div className="homefeed-right-container">
            {smallScreen && (
              <div className="mobile-stats-card-container">
                {MobileStatsCard(
                  "Profile",
                  `${
                    profileCompletionPercentage && profileCompletionPercentage
                  }% complete`,
                  true
                )}
              </div>
            )}
            <div className="grid-card">
              <HandlInfoCard smallScreen={smallScreen} />
            </div>
            <div
              style={smallScreen ? { paddingInline: "16px" } : {}}
              className="grid-card"
            >
              <InsuranceSearchCard smallScreen={smallScreen} state={state} />
            </div>
            <div className="grid-card">
              <ArticleCard articles={articles} smallScreen={smallScreen} />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
}

export default HomeFeed;
