import axios from 'axios';
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useState } from 'react';
import { send } from 'emailjs-com';
import TextInput from '../elements/inputs/TextInput';
import TextAreaInput from '../elements/inputs/TextAreaInput';
import Form from 'react-bootstrap/Form';
import Button from "../elements/Button";
import './ContactForm.css'

function ContactForm(props) {
  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, '\n');
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  const [validated, setValidated] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const [toSend, setToSend] = useState({
    from_name: '',
    from_email: '',
    message: ''
  });

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + 'email_lists';
    axios.post(URL, {
        contact_first_name: name,
        contact_last_name: '',
        contact_email: email_address,
        query_type: 'contact us'
      })
      .then(res => {});
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      
      e.stopPropagation();
      console.log('form not valid');
    } else {
      console.log('form is valid');
      setValidated(true);
    }

    const SERVICE_ID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAIL_JS_USER_ID;

    send(
      SERVICE_ID,
      TEMPLATE_ID,
      toSend,
      USER_ID
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowForm(false);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });

    
    const email_address = toSend.from_email;
    const name = toSend.from_name;
    const today = new Date();
    const newRow = { Email: email_address, Name: name, 'Submitted On': today.toLocaleDateString("en-US"), 'Query Type': 'contact us' };

    // appendSpreadsheet(newRow);
    // createContact(email_address, name);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  let disclaimer = null;
  if (props.disclaimer == true) {
    disclaimer = (<div className='contact-form__disclaimer'>Please note, this form is not for seeking medical attention. If you are experiencing a health emergency, please call 911.</div>)
  }

  const displayForm = () => {
    setShowForm(true);

    setToSend({
      from_name: null,
      from_email: null,
      message: null
    });

    setValidated(false);
  }

  if (showForm) {
    return (
      <div className='contact-section__contact-form'>
      <p className='contact-section__body-text mobile-hide'>Drop us a note with your question and we’ll get back to you within 1-2 business days.</p>
      <p className='contact-section__body-text desktop-hide'>We’re here to help make your health journey easier. Drop us a note with your question and we’ll get back to you within 1-2 business days.</p>
        <Form noValidate validated={validated} onSubmit={onSubmit} className='contact-form__container' id='contact-form-container' >
          <TextInput
            type='text'
            name='from_name'
            value={toSend.from_name}
            inputClasses= 'contact-form__input'
            placeholder='Name'
            formGroupClasses= 'w-100'
            onChange={handleChange} 
            autoFocus={false}/>
          <TextInput
            type='text'
            name='from_email'
            value={toSend.from_email}
            inputClasses= 'contact-form__input'
            placeholder="Email Address"
            formGroupClasses= 'w-100'
            onChange={handleChange} 
            autoFocus={false}/>
          <TextAreaInput
            type='text'
            name='message'
            value={toSend.message}
            inputClasses= 'contact-form__input'
            placeholder='How can we help?'
            formGroupClasses= 'w-100'
            onChange={handleChange} />
            {disclaimer}
          <Button 
            active={true}
            class="long-btn filled" 
            label="SUBMIT MY QUESTION" />
        </Form>
      </div>
    );
  } else {
    return (
      <div className='contact-section__contact-form'>
        <p className='contact-form__thank-you-text' style={{marginTop: props.thankYouMargin}}>Thank you! We’ll let you know when we’ve published the answer to your question.</p>
        <Button active={true} class="long-btn filled" label="SUBMIT ANOTHER QUESTION" onClick={(e) => displayForm()}/>
      </div>
    );
  }
}
export default ContactForm;
