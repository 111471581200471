import "./SignIn.css";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import { useState, useEffect } from "react";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import SignInSideBar from "./SignInSideBar";
import FAQAccordian from "./FAQAccordian";
import useToken from "../../hooks/useToken";
import { useSelector } from "react-redux";

SignIn.propTypes = {
  setToken: PropTypes.func.isRequired,
};

function SignIn({ setToken }) {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [remember, setRemember] = useState(false);
  const [passwordVisibiliy, setPasswordVisibility] = useState(false);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useToken();
  const [userID, setUserID] = useState("");
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (currentUser.loggedIn === true) {
      history.push({
        pathname: "/",
      });
    }
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    signInUser();
  }

  function getUserInformation() {
    if (!token || userID === "") return;
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        if (userInformation.is_onboarding_complete === true) {
          history.push({
            pathname: "/",
          });
        } else {
          history.push({
            pathname: "/onboarding",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function signInUser() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "login";
    axios
      .post(URL, {
        user: {
          email: email,
          password: password,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setUserID(res.data.data.id);
          const token = res.headers.authorization ?
            res.headers.authorization.token :
            res.headers['x-amzn-remapped-authorization'];
          setToken(token, remember);
          getUserInformation();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.error === "Invalid Email or password.") {
          setErrorMessage("Email or password are incorrect.");
        } else if (
          error.response.data.error ===
          "You have one more attempt before your account is locked."
        ) {
          setErrorMessage(error.response.data.error);
        } else if (error.response.data.error === "Your account is locked.") {
          setErrorMessage(
            "Your account is locked. Please check your email for instructions."
          );
        }
      });
  }

  function goToHomefeed() {
    history.push({
      pathname: "/",
    });
  }

  function goToSignUp() {
    history.push({
      pathname: "/sign_up",
    });
  }

  function goToForgotPassword() {
    history.push({
      pathname: "/forgot_password",
    });
  }

  const SignInForm = () => {
    function togglePasswordVisibility() {
      setPasswordVisibility(!passwordVisibiliy);
    }

    var inputType = passwordVisibiliy === true ? "text" : "password";

    return (
      <form className="sign-in-form" onSubmit={(e) => onSubmit(e)} noValidate>
        <Input
          placeholder="Email"
          name="work-email"
          label="Work Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          inputContainerClasses="sign-in-form__input"
          showArrow={false}
          readOnly={false}
        />
        <Input
          placeholder="Password"
          type={inputType}
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          inputContainerClasses="sign-in-form__input"
          showArrow={false}
          readOnly={false}
          showVisibilityToggle={true}
          iconOnClick={() => togglePasswordVisibility()}
        />

        <div className="sign-in-form__remember-me">
          <input
            type="checkbox"
            onChange={(e) => setRemember(!remember)}
            checked={remember}
          />
          <p>Remember Me</p>
        </div>
        <span className="sign-in-form__remember-me-text">
          By checking this box, you will be able to skip entering your password
          and sign in using the code sent to your work email.
        </span>

        <div className="sign-in-btn">
          <Button
            label="Log In"
            active={email !== "" && password !== ""}
            class="long-btn filled"
          />
        </div>
      </form>
    );
  };

  const OtherActions = () => {
    return (
      <div className="sign-in__other-actions">
        <Button
          label="Forgot Password?"
          active={true}
          class="long-btn text-btn"
          onClick={() => goToForgotPassword()}
        />

        <div className="sign-in__or-element">
          <div className="sign-in__or-bar" />
          <span> OR </span>
          <div className="sign-in__or-bar" />
        </div>

        <div className="sign-in__outline-btn">
          <Button
            label="Create New Account"
            active={true}
            class="outline-btn"
            onClick={() => goToSignUp()}
          />
        </div>
      </div>
    );
  };

  const SignInContent = () => {
    return (
      <React.Fragment>
        <h1>Health looks good on you.</h1>
        <p className="sign-in__subtitle">{errorMessage}</p>
        {SignInForm()}
        {OtherActions()}
      </React.Fragment>
    );
  };
  const accordianInformation = [
    {
      title: "I forgot my work email.",
      body: "I forgot my work email.",
    },
    {
      title: "How do I contact Handl support?",
      body: "How do I contact Handl support?",
    },
  ];
  return (
    <>
      <SidebarLayout
        signIn
        sidebar={<SignInSideBar />}
        sidebarClasses="sign-in__sidebar"
        content={SignInContent()}
        contentClasses="sign-in__content"
        userStatus="logged-out"
        showFooter={false}
      />
      <a className="sign-in__privacy-link" href="#">
        Handl Health Privacy & Terms
      </a>
      <FAQAccordian accordianInformation={accordianInformation} />
    </>
  );
}

export default SignIn;
