import ContactForm from '../modules/ContactForm';
import './ContactSection.css';
import { useState, useRef, useEffect } from 'react';

function ContactSection(props) {
  const ref = useRef(null);
  const [thankYouMargin, setThankYouMargin] = useState(0)

  useEffect(() => {
    function adjustThankYou() {
      if (ref.current != null) {
        let height = ref.current.clientHeight;
        
        setThankYouMargin(height);
      }
    }

    adjustThankYou();
    window.addEventListener('resize', adjustThankYou)
    
  })

  return (
    <div className='contact-section__contact-container'>
      <div className='contact-section__contact-content'>
        <div className='contact-section__contact-body'>
          <div className='contact-section__contact-description'>
            <p className='contact-section__body-heading' ref={ref}>Don’t see what you’re looking for? </p>
            <p className='contact-section__body-text mobile-hide'>We’re here to help make your health journey easier.</p>
          </div>

          <ContactForm 
            disclaimer={true}
            thankYouMargin={thankYouMargin} />
        </div>
      </div>
    </div>
  )
}

export default ContactSection;
