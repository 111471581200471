import React, {useState} from 'react'
import Button from '../../components/elements/Button';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import right from "../../assets/icons/onboarding/right.svg"


function StepFive(props) {
  const userID = props.userID
  const token = props.token
  
  const history = useHistory()
  const location = useLocation()
   // update user information
   function updateUserInformation(data, location) {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/' + userID;
    axios.patch(URL, { user_information: data }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(res => { 
          if (res.status == 200 && location==='home') {
          history.push({
            pathname: '/',
          })
            
          } 
        }).catch(function(error) {
          console.log(error.response);
        });
  }
  function onboardingUpdateHandler(location=null) {
    const updateRequest = ({is_onboarding_complete:true})
    updateUserInformation(updateRequest, location)
  }

  function goToSearch() {
    if (!props.zipcode) return
    onboardingUpdateHandler();
    history.push({pathname:'/fancy_search', state: {from: location.pathname, zipcode: props.zipcode, searchActive:true, serviceName: 'Annual Checkup for Patients 18-39 Years in Age', zipcodeIsValid:true, cptcode: 99385}})
  }
    return (
        <>
        <p className='body-2 pointer' onClick={()=>{props.switchPage('stepFour'); props.setProgressPercentage(80)}}><span className='back-arrow' /> BACK</p>
       <h2 className='onboarding__landing-header-secondary' >Congrats! You’ve set up your Handl profile.</h2>
       <p className='onboarding-body-1'>You can update this information at any time in your account settings.</p>
      <div className='onboarding__step5-content'>
        <p className='onboarding__step5-subtitle'>Check out these best price preventative services in your area:</p>
        <div onClick={()=>goToSearch()} style={{cursor:'pointer'}} className='flex onboarding__step5-content-box'>
            <div style={{display:'flex', flexDirection:'column'}}>
            <p className='onboarding__cpt-text'>CPT 99385</p>
            <p className='onboarding__checkup-text'>Annual Checkup</p>
            </div>
            
            <div className="flex">
            <p className='onboarding__best-price'>Best Price</p>
            <img src={right}/>

        </div>
        </div>

        <div className='flex onboarding__step5-content-box'>
            <div style={{display:'flex', flexDirection:'column'}}>
            <p className='onboarding__cpt-text'>CPT 99386</p>
            <p className='onboarding__checkup-text'>Annual Checkup</p>
            </div>
            
            <div className="flex">
            <p className='onboarding__best-price'>Best Price</p>
            <img src={right}/>
        </div>
        </div>
      </div>
      <Button label="go to my home feed" onClick={()=>onboardingUpdateHandler("home")} active={true} class="long-btn filled"/>
    </>
    )
}

export default StepFive