import './SignUp.css';
import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import { useState, useEffect, useRef } from 'react';
import React, {Fragment} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import SidebarLayout from '../../components/templates/SidebarLayout.js';
import PopupModal from '../../components/templates/PopupModal';
import useToken from '../../hooks/useToken';


function SignUp(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [consented, setConsented] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(null);
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [passwordConfirmationIsValid, setPasswordConfirmationIsValid] = useState(null);
  const [accessCode, setAccessCode] = useState('');
  const [currentPage, setCurrentPage] = useState('form');
  const [accessCodeIsValid, setAccessCodeIsValid] = useState(null);
  const [accessCodeResent, setAccessCodeResent] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('Please enter a valid email address.');
  const [userExists, setUserExists] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { token } = useToken();

  const [userId, setUserId] = useState(null)

  const history = useHistory();

  function onSubmit(e) {
    e.preventDefault();
    if (currentPage == 'form') {
      createUser();
    } else if (currentPage == 'confirmation') {
      setAccessCodeIsValid(null);
      confirmUser();
    }
    
  }

  function switchPage(page) {
    setCurrentPage(page);
    window.scrollTo(0, 0);
    setPasswordConfirmationIsValid(null);
    setPasswordIsValid(null);
    setPassword('');
    setPasswordConfirmation('');
    setConsented(false);
    setFirstName('')
    setLastName('')
    setEmail('')
    if (showModal) {
      closeModal()      
    }
  }

  function goToSignIn() {
    history.push({
      pathname: '/sign_in',
      state: { from_sign_up: true }
    })
  }

  function deleteAccountHandler() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users/' + +userId + '/destroy_unconfirmed_user';
    axios.delete(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status == 200) {
        switchPage('form');
        window.scrollTo(0, 0);
      } else {
        // Error
      }
    }).catch(function(error) {
      // Error
      console.log(error.response);
    });
    }
    



  const createUser = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'signup';
    axios.post(URL, {
        user: {
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          hipaa_consented: consented
        },

        user_information: {
          first_name: firstName,
          last_name: lastName
        }
      }).then(res => {
        setUserId(res.data.data.id)
        switchPage('confirmation');
      }).catch(function(error) {
        const errors = error.response.data.errors;
        const emailExists = errors.some(e => e.detail === 'Email already exists.');
        if (emailExists == true) {
          const emailExistsError = <span>There is already an account associated with that email. <a href='/sign_in'><u>Log in here.</u></a></span>
          setEmailErrorMessage(emailExistsError);
          setUserExists(true);
        }
      });
  }

  const confirmUser = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'confirmation';
    axios.get(URL, {
          params: {
            email: email,
            confirmation_token: accessCode
          }
        })
      .then(res => { 
          if (res.status == 200) {
            setAccessCodeIsValid(true);
            goToSignIn();
          } else {
            setAccessCodeIsValid(false);
            setAccessCodeResent(false);
          }
        }).catch(function(error) {
          console.log(error.response);
          setAccessCodeIsValid(false);
          setAccessCodeResent(false);
        });
  }

  const resendAccessCode = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'confirmation';
    axios.post(URL, {
          user: {
            email: email
          }
        })
      .then(res => { 
        setAccessCodeResent(true);
      });
  }

  function closeModal () {
    setShowModal(false)
  }
  function validateName() {
    return firstName != '' && lastName != '';
  }

  function validateEmail() {
    const validEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (email == '') {
      return null;
    } else {
      return validEmail != undefined;
    }
  }

  function validatePassword() {
    
    if (password == '') {
      return null
    } else {
      const uppercaseRegExp   = /(?=.*?[A-Z])/;
      const lowercaseRegExp   = /(?=.*?[a-z])/;
      const digitsRegExp      = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp   = /.{12,}/;
      const passwordLength =      password.length;

      const uppercasePassword =   uppercaseRegExp.test(password);
      const lowercasePassword =   lowercaseRegExp.test(password);
      const digitsPassword =      digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword =   minLengthRegExp.test(password);

      return uppercasePassword && specialCharPassword && minLengthPassword
    }
  }

  function validatePasswordConfirmation() {
    if (passwordConfirmation == '') {
      return null
    } else {
      return password != '' && passwordConfirmation != '' && password == passwordConfirmation;
    }
  }

  function handleAccessCodeChange(val) {
    setAccessCode(val);

    if (val == '') {
      setAccessCodeIsValid(null);
      setAccessCodeResent(false);
    }
  }

  function handleEmailChange(val) {
    setEmail(val);

    if (val == '') {
      setEmailIsValid(null);
      setEmailErrorMessage('Please enter a valid email address.');
      setUserExists(false);
    }
  }

  const SignUpForm = () => {
    return (
      <React.Fragment>
        <p className='body-2'>Step 1 of 2</p>
        <h1>We’re glad you’re here!</h1>
        <p className="sign-up__subtitle">To make sure it’s you, enter your name and work email. We’ll get to the personal stuff later.</p>

        <form id='sign-up-form' onSubmit={(e) => onSubmit(e)} noValidate>
          <Input
            placeholder="First name"
            name="first-name"
            label="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            inputContainerClasses='sign-up-form__input'
            showArrow={false}
            readOnly={false}
          />

          <Input
            placeholder="Last name"
            name="last-name"
            label="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            inputContainerClasses='sign-up-form__input'
            showArrow={false}
            readOnly={false}
          />

          <Input
            placeholder="Work email"
            name="work-email"
            label="Work email"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            onBlur={(e) => setEmailIsValid(validateEmail())}
            inputContainerClasses='sign-up-form__input '
            showArrow={false}
            readOnly={false}
            errorMessage={emailErrorMessage}
            showErrorMessage={(email != '' && emailIsValid == false) || userExists}
            showErrorIcon={true}
          /> 

          <Input
            placeholder="Password"
            type="password"
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={(e) => setPasswordIsValid(validatePassword())}
            inputContainerClasses='sign-up-form__input with-help-text'
            showArrow={false}
            readOnly={false}
            helpText="Passwords must be at least 12 characters, including 1 uppercase letter and 1 symbol."
            errorMessage="Please use at least 12 characters, including 1 uppercase letter and 1 symbol."
            validMessage="Passwords match."
            showErrorMessage={password != '' && passwordIsValid == false}
            showValidMessage={passwordIsValid == true && passwordConfirmationIsValid == true}
            showErrorIcon={true}
          /> 

          <Input
            placeholder="Re-enter Password"
            type="password"
            name="password-confirmation"
            label="Re-enter Password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            onFocus={(e) => setPasswordConfirmationIsValid(validatePasswordConfirmation())}
            onBlur={(e) => setPasswordConfirmationIsValid(validatePasswordConfirmation())}
            inputContainerClasses='sign-up-form__input with-help-text'
            showArrow={false}
            readOnly={false}
            errorMessage="Passwords do not match. Please re-enter."
            validMessage="Passwords match."
            showErrorMessage={passwordConfirmation != '' && passwordConfirmationIsValid == false}
            showValidMessage={passwordIsValid == true && passwordConfirmationIsValid == true}
            showErrorIcon={true}
          /> 

          <div className='sign-up-form__consent-input'>
            <input type='checkbox' onChange={(e) => setConsented(!consented)} checked={consented} />
            <p className='consent-text'>I agree with Handl Health’s <a className='sign-up-form__link'>Terms</a>, <a className='sign-up-form__link'>Privacy Policy</a>, and <a className='sign-up-form__link'>E-Sign consent</a>.</p>
          </div>

          <Button 
            label="Next: Security Code"
            active={validateName() && validateEmail() && validatePassword() && validatePasswordConfirmation() && consented == true}
            class="long-btn filled"
          />
        </form>
      </React.Fragment>
    );
  }

  const EmailConfirmation = () => {
    const accessCodeResendHelper = accessCodeResent ? <p className='access-code-helper sent'>Code sent! This may take a few mins.</p> : <a className='sign-up-form__link access-code-helper' onClick={(e) => resendAccessCode()}>Resend my code.</a>
    return(
      <React.Fragment>
        <PopupModal noPadding show={showModal} close={closeModal} size="md" dialogClassName="my-modal" position="centered" 
        body={<div className="sign-up__modal-body">
          <p className="sign-up__modal-header">Are you sure you want to go back?</p>
          <p className='sign-up__modal-text'>This will delete any progress you’ve made in creating your account.</p> 
          <Button 
            label="Go Back"
            active={true}
            class="outline-btn-blue"
            onClick={()=> deleteAccountHandler()}
          />  
          <Button 
            label="Cancel"
            active={true}
            class="long-btn text-btn"
            onClick={()=> setShowModal(false)}
          /></div>}/>
        <p className='body-2 pointer' onClick={() => setShowModal(true)}><span className='back-arrow' /> Step 2 of 2</p>
        <h1>Check your work email.</h1>
        <p className="sign-up__subtitle">At Handl Health, we prioritize your security. Anytime you log-in, we’ll send you an access code. No password breaches over here.</p>

        <form id='email-confirmation-form' onSubmit={(e) => onSubmit(e)} noValidate>
          <Input
            placeholder="Access Code"
            name="access-code"
            label="Access Code"
            value={accessCode}
            onChange={(e) => handleAccessCodeChange(e.target.value)}
            inputContainerClasses='access-code__input'
            showArrow={false}
            readOnly={false}
            helpText="Your unique access code will expire in 5 minutes."
            errorMessage="Hmm, that isn't the code we sent you."
            validMessage="Valid code."
            showErrorMessage={accessCodeIsValid == false}
            showValidMessage={accessCodeIsValid == true}
            showErrorIcon={true}
          />

          <Button 
            label="Build My Profile"
            active={accessCode != ''}
            class="long-btn filled build-profile-btn"
          />

          <div className='access-code-helper-container'>
            {accessCodeResendHelper}
          </div>


        </form>
      </React.Fragment>
    )
  }

  const SideBar = (page) => {
    var topText;
    var bottomText;
    var image;

    if (page == 'form') {
      topText = "You’ve got health benefits (woot!).";
      bottomText = "We’ll help you use them.";
      image = "https://storage.googleapis.com/handlhealth/Powerful-rafiki%201.svg"
    } else {
      topText = "Next up, full-service concierge health benefits.";
      bottomText = "Just for you.";
      image = "https://storage.googleapis.com/handlhealth/Finish%20line-bro%201.svg"
    }

    return(
      <React.Fragment>
        <div className='sign-up__side-bar-text'>
          <h2>{topText}</h2>
          <h2>{bottomText}</h2>
        </div>
        <img src={image} />
      </React.Fragment>
    )
  }

  return (
    <SidebarLayout 
      sidebar={SideBar(currentPage)}
      sidebarClasses='sign-up__side-bar'
      content={currentPage == 'form' ? SignUpForm() : EmailConfirmation()}
      contentClasses='sign-up__content'
      userStatus='logged-out'
      showFooter={false}
    />
  )
}

export default SignUp;
