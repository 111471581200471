import {
  QuoteNavigation,
  MobileNavigation,
  MemoizedUserNavigation,
} from "../sections/Navigation";
import Footer from "../sections/Footer";
import "./Mobile.css";

function Mobile(props) {
  var quoteNavigation;
  var mobileNav;
  var footer;
  var classes = "quote-navigation " + props.quoteNavClasses;
  var lastPage = "BACK TO " + props.lastPage;
  var mobileNavColor = props.mobileNavColor;

  if (props.showQuoteNavigation) {
    quoteNavigation = (
      <MemoizedUserNavigation
        back={props.back}
        lastPage={lastPage}
        classes={classes}
        textClasses={props.quoteNavTextClasses}
        arrowClasses={props.arrowClasses}
      />
    );
  }

  if (props.showMobileNav) {
    mobileNav = <MemoizedUserNavigation color={mobileNavColor} />;
  }

  if (props.showFooter) {
    footer = <Footer />;
  }

  return (
    <main className="h-100" id="main-content">
      {mobileNav}
      {quoteNavigation}
      {props.body}
      {footer}
    </main>
  );
}

export default Mobile;
