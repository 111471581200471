import './Footer.css';
import instagram from '../../assets/icons/instagram.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import facebook from '../../assets/icons/facebook.png';
import { Link } from 'react-router-dom';

function  Footer(props) {
  return (
    <footer className={props.className}>
      <div className="footer-container">
        <div className="footer-row footer-links">
          <Link className='footer-link' to={
            {pathname: '/about_us'}
            }>
            About Us
          </Link>
        </div>
        <div className="footer-row footer-icons">
          <a href="https://www.instagram.com/handlhealth/" target="_blank">
            <img src={instagram} width="40" height="40" />
          </a>
          <a href="https://www.linkedin.com/company/handl-health" target="_blank">
            <img src={linkedin} width="36" height="36" />
          </a>

          <a href="https://www.facebook.com/Handl-Health-103600331988081" target="_blank">
            <img src={facebook} width="40" height="40" />
          </a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
