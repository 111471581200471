import './SignInSideBar.css'
import sidebar from "../../assets/sidebar_reset.svg"
import signinMinionNewHHLogo from "../../assets/signinMinionNewHHLogo.svg"

function SignInSideBar(props) {
  return (
    <div className='sign-in__design-blue'>
      <svg viewBox="0 0 317 894" fill="none" preserveAspectRatio='none' xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M-22.4998 892.5C-22.4998 892.5 46.9521 934.575 53.3382 483.299C59.7243 32.0241 316.912 -2.54859e-05 316.912 -2.54859e-05L-22.5001 -4.49528e-05L-22.4998 892.5Z" fill="#4C6BBB"/>
      </svg>
      {props.resetImage === true ? 
      <img className="sign-in__design-reset-image" src={sidebar}/>
      : 
      props.bookingComplete === true ?
      <img className="booking-complete__bottom-minion" src='https://storage.googleapis.com/handlhealth/BookingCompleteBottomMinion.svg'/>
      :
      <img className="sign-in__design-default-image" src={signinMinionNewHHLogo} /> 
      
    }
      {/* <img src={`https://storage.googleapis.com/handlhealth/${imageUrl}.svg`} /> */}
    </div>
  );
}

export default SignInSideBar;
