import React, { useState, useEffect } from "react";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import { useSelector } from "react-redux";

export default function CoverageCard({ smallScreen }) {
  const [userInsuranceInformation, setUserInsuranceInformation] = useState([
    {
      planType: "",
      memberID: "",
      insurancePlan: "",
      deductible: "",
      oop_max: "",
    },
  ]);
  const userID = useSelector((state) =>
    typeof state?.currentUser?.currentUser?.id === "string"
      ? state?.currentUser?.currentUser?.id
      : null
  );
  const { token } = useToken();

  useEffect(() => {
    getUserInsuranceInformation();
  }, []);

  // get user insurance information
  function getUserInsuranceInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setUserInsuranceInformation({
          planType: userInformation.plan_type ? userInformation.plan_type : "",
          insurancePlan: userInformation.issuer_legal_name[0]
            ? userInformation.issuer_legal_name[0]
            : "",
          memberID: userInformation.member_id ? userInformation.member_id : "",
          deductible: userInformation.deductible
            ? userInformation.deductible
            : 2000,
          oop_max: userInformation.max_oop ? userInformation.max_oop : 4000,
        });
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  const coverageContent = [
    {
      title: "Individual Plan",
      firstItemTitle: "Insurance Plan",
      firstItem: userInsuranceInformation?.insurancePlan,
      secondItemTitle: "Member ID",
      secondItem: userInsuranceInformation?.memberID,
    },
    {
      title: "In-Network",
      firstItemTitle: "Deductible",
      firstItem: `$${userInsuranceInformation?.deductible}`,
      secondItemTitle: "Out-of-pocket max",
      secondItem: `$${userInsuranceInformation?.oop_max}`,
    },
    {
      title: "Out-of-Network",
      firstItemTitle: "Deductible",
      firstItem: `$${userInsuranceInformation?.deductible * 2}`,
      secondItemTitle: "Out-of-pocket max",
      secondItem: `$${userInsuranceInformation?.oop_max * 2}`,
    },
  ];
  return (
    <div className="home-card coverage-card">
      <p
        className={
          smallScreen
            ? "margin-bottom-0"
            : "margin-bottom-0 heading-poppins fw-600"
        }
      >
        Coverage
      </p>
      <div>
        {coverageContent.map((item, idx) => (
          <div key={idx} style={{ marginBottom: "24px" }}>
            <p
              className={
                smallScreen ? "" : "text-description fw-600 margin-bottom-8"
              }
            >
              {item.title}
            </p>
            <p className={smallScreen ? "" : "body-2 margin-bottom-4"}>
              {item.firstItemTitle}:{" "}
              <span className="fw-500">{item.firstItem}</span>
            </p>
            <p>
              {item.secondItemTitle}:{" "}
              <span className="fw-500">{item.secondItem}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
