import { useState, useEffect, useRef } from 'react';
import CroppedImage from "../../components/elements/CroppedImage";
import TextTruncate from 'react-text-truncate'; 
import { Link } from 'react-router-dom';

function FirstArticle(props) {
  const [summaryLines, setSummaryLines] = useState(5);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const widthRef = useRef(null);
  const ref = useRef(null);

  const article = props.article;

  let dateAndKeywords = (<span>{article.date + ' • '}<span className='article-list__article-keywords'>{article.article_keywords.join(', ')}</span></span>);
  let draft = article.status == 'Draft' ? <div className='bold'>{article.status}</div> : null


  useEffect(() => {
    function adjustSummary() {
      if (ref.current != null) {
        let height = ref.current.clientHeight;
        let totalHeight = height + 32;
        let lines = Math.floor(totalHeight / 30);

        let imageHeight = totalHeight * 1.25;
        setImageHeight(imageHeight);

        let width = widthRef.current.clientWidth;
        setImageWidth(width);
        
        setSummaryLines(lines);
      }
    }

    adjustSummary();
    window.addEventListener('resize', adjustSummary)
    
  })

  return(
    <Link key={article.url} className='article-list__article-link' to={{pathname: `/articles/${article.url}`}} >
      <li className='article-list__article first-article' ref={widthRef}>
        
        <CroppedImage 
          height={imageHeight}
          width={imageWidth}
          src={article.header_image}
          />

        <div className='article-list__article-details'>
          <div className='article-list__article-information' ref={ref} >
            <p className='article-list__article-title'>{article.title}</p>
            <div className='article-list__article-date-and-keywords'>{dateAndKeywords}</div>
            {draft}
            <div className='article-list__article-author'>
              <img className='article-list__author-icon' src={article.author_image} />
              <div className='article-list__author-name'>{article.author_name}</div>
            </div>
          </div>
          <p className='article-list__article-summary'>
            <TextTruncate
              line={summaryLines}
              element="span"
              truncateText="…"
              text={article.excerpt}
              textTruncateChild={<span className='article-list__article-summary-read-more'>READ MORE</span>}
            />
          </p>
        </div>
      </li>
    </Link>
  );
}

export default FirstArticle;
