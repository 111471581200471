import React, { Component, Fragment } from "react";
import "./FacilityEstimate.css";
import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect, useReducer, useRef } from "react";
import Desktop from "../../components/templates/Desktop";
import Mobile from "../../components/templates/Mobile";
import axios from "axios";
import Button from "../../components/elements/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import ShareFeedback from "../../components/modules/ShareFeedback";
import PopupModal from "../../components/templates/PopupModal";
import SendEstimate from "../../components/modules/SendEstimate";
import MobileSelect from "../../components/elements/inputs/MobileSelect";
import Form from "react-bootstrap/Form";
import EstimatePdf from "../../components/modules/EstimatePdf";
import Loading from "../../components/modules/Loading";
import QuestionSection from "../../components/sections/QuestionSection";
import checkMark from "../../assets/icons/checkmark.svg";
import heart from "../../assets/icons/heart.png";
import right from "../../assets/icons/right.svg";
import left from "../../assets/icons/left.svg";

import star from "../../assets/icons/star.svg";
import filledStar from "../../assets/icons/filledStar.svg";

import filledHeart from "../../assets/icons/filledHeart.svg";

import PriceBars from "../Service/PriceBars";
import { Link } from "react-router-dom";
import Input from "../../components/elements/Input";
import Footer from "../../components/sections/Footer";
import WhereYoullGo from "./WhereYoullGo";
import UnderstandingYourEstimate from "./UnderstandingYourEstimate";
import ServiceDetailSection from "./ServiceDetailSection";

function FacilityEstimate() {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState(location.state.data);
  const [smallScreen, setSmallScreen] = useState(false);
  const [quote, setQuote] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updatedCoverage, setUpdatedCoverage] = useState(false);
  const [showCoverageForm, setShowCoverageForm] = useState(false);
  const [hasInsurance, setHasInsurance] = useState(
    data?.insuranceProvider != "I do not have insurance" &&
      data?.insuranceProvider != ""
  );
  const [lowestOopValue, setLowestOopValue] = useState(0);

  const [facility, setFacility] = useState({});
  const [service, setService] = useState({});
  const [serviceData, setServiceData] = useState({});

  const [estimateSaved, setEstimateSaved] = useState(
    location.state?.apptmt
      ? !location.state?.apptmt?.hasOwnProperty("booking_status")
        ? true
        : false
      : false
  );
  const [contentUpdated, setContentUpdated] = useState(false);
  const [serviceDataLoaded, setServiceDataLoaded] = useState(false);
  const costRef = useRef();

  // navigation
  const [currentPosition, setCurrentPosition] = useState("Estimate details");

  const estimateDetailsRef = useRef();
  const costAndLocationRef = useRef();

  const qualityRatingsRef = useRef();

  const otherEstimatesRef = useRef();

  const serviceDetailsRef = useRef();

  useEffect(() => {
    const handleIntersection = function (entries) {
      entries.forEach((entry) => {
        if (entry.target.id !== currentPosition && entry.isIntersecting) {
          if (entry.target.id === "estimate-callout") {
            setCurrentPosition("Estimate details");
          } else if (entry.target.id === "cost-and-location") {
            setCurrentPosition("Cost and location");
          } else if (entry.target.id === "quality-ratings") {
            setCurrentPosition("Quality ratings");
          } else if (entry.target.id === "service-details") {
            setCurrentPosition("Service details");
          } else if (entry.target.id === "other-estimates") {
            setCurrentPosition("Other estimates");
          }
        }
      });
    };
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    if (
      costAndLocationRef &&
      costAndLocationRef.current &&
      serviceDetailsRef &&
      serviceDetailsRef.current
    ) {
      const observer = new IntersectionObserver(handleIntersection, options);
      observer.observe(costAndLocationRef.current);
      const observerTwo = new IntersectionObserver(handleIntersection, options);
      qualityRatingsRef.current &&
        observerTwo.observe(qualityRatingsRef.current);
      const observerThree = new IntersectionObserver(handleIntersection, {
        threshold: 0.3,
        rootMargin: "0px",
      });
      observerThree.observe(serviceDetailsRef.current);
      const observerFour = new IntersectionObserver(
        handleIntersection,
        options
      );
      otherEstimatesRef.current &&
        observerFour.observe(otherEstimatesRef.current);
      const observerFive = new IntersectionObserver(
        handleIntersection,
        options
      );
      observerFive.observe(estimateDetailsRef.current);
      return () => {
        observer.disconnect();
        observerTwo.disconnect();
        observerThree.disconnect();
        observerFour.disconnect();
        observerFive.disconnect();
      };
    }

    // Clenaup the observer if component unmount.
  }, [loaded]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (location.state.apptmt) return;
    getQuote();
    fetchFacilityData();
    fetchServiceData();
    setLowestOopValue(calculateOopMaxPercentage(data.oopMax));
    if (
      data.listOfSavedEstimates?.find(
        (element) => element.service_cost_id === data.serviceCostID
      )
    ) {
      setEstimateSaved(true);
    }
  }, []);

  useEffect(() => {
    if (location.state?.apptmt) {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (contentUpdated) {
      setLoaded(false);
      getQuote();
      fetchFacilityData();
      fetchServiceData();
    }
  }, [contentUpdated]);

  function getQuote() {
    const url = process.env.REACT_APP_API_URL + "service_costs/cost_estimate";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          facility_id: data.id,
          deductible: data.deductible,
          is_deductible_met: data.isDeductibleMet,
          deductible_percentage: data.deductiblePercentage,
          max_oop: data.oopMax,
          is_max_oop_met: data.isMaxOopMet,
          max_oop_percentage: data.oopMaxPercentage,
          service: data.service,
          zip_code: data.zipCode,
          insurance_provider:
            data.isCashDiscountActive === true
              ? "I do not have insurance"
              : data.insuranceProvider,
          // Pass facility name for legacy reasons
          facility: data.facility,
        },
      })
      .then((res) => {
        setQuote(res.data);
      });
  }

  async function fetchFacilityData() {
    const url = process.env.REACT_APP_API_URL + "facilities/display";
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          id: data.id,
          service_id: data.serviceID,
        },
      })
      .then((res) => {
        setFacility(res.data);
      })
      .catch((err) => console.error(err));
  }

  async function fetchServiceNationalPriceData() {
    const url =
      process.env.REACT_APP_API_URL +
      "services/retrieve_national_price_variation";
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          id: data.serviceID,
          zip_code: data.zipCode,
          distance: data.distance,
        },
      })
      .then((res) => {
        setServiceData(res.data.data.attributes);
        setServiceDataLoaded(true);
      })
      .catch((err) => console.error(err));
  }

  async function fetchServiceData() {
    const url = process.env.REACT_APP_API_URL + "services/indexable_service";
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          id: data.serviceID,
          zip_code: data.zipCode,
          distance: data.distance,
        },
      })
      .then((res) => {
        setService(res.data.data.attributes);
        setContentUpdated(false);
        setLoaded(true);
      })
      .catch((err) => console.error(err));
  }

  async function saveEstimate() {
    if ((data?.userID && data?.serviceCostID) || location.state.apptmt) {
      const url = process.env.REACT_APP_API_URL + "saved_user_services";
      await axios
        .post(
          url,
          {
            service_cost_id:
              data?.serviceCostID ?? location.state.apptmt?.service_cost_id,
            user_id: data?.userID ?? location.state.apptmt?.user_id,
            facility_detail:
              data?.facilityDetail ?? location.state.apptmt?.facilityDetail,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
            },
          }
        )
        .then((res) => {
          setEstimateSaved(true);
          const currentEstimate = data?.facilitiesList.find(
            (element) => element.name == facility.name
          );
          currentEstimate && data?.listOfSavedEstimates.push(currentEstimate);
        })
        .catch((err) => console.error(err));
    }
  }

  async function removeEstimate() {
    if (
      ((data?.userID && data?.serviceCostID) || location.state.apptmt) &&
      estimateSaved === true
    ) {
      const url =
        process.env.REACT_APP_API_URL + "saved_user_services/" + "destroy";
      await axios
        .post(
          url,
          {
            service_cost_id:
              data?.serviceCostID ?? location.state.apptmt?.service_cost_id,
            user_id: data?.userID ?? location.state.apptmt?.user_id,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
            },
          }
        )
        .then((res) => {
          const currentEstimate = data?.facilitiesList.find(
            (element) => element.name == facility.name
          );
          const updatedSavedEstimates = data?.listOfSavedEstimates.filter(
            (estimate) => estimate.name !== currentEstimate.name
          );
          updatedSavedEstimates &&
            setData({ ...data, listOfSavedEstimates: updatedSavedEstimates });
          setEstimateSaved(false);
        })
        .catch((err) => console.error(err));
    }
  }

  const onClick = () => {
    if (location.state?.apptmt) {
      history.push({ pathname: location?.state?.from ?? "/appointments" });
    } else {
      history.push({
        pathname: "/fancy_search",
        state: {
          prevPath: location.pathname,
          service: data.service,
          serviceName: data.serviceName,
          zipCode: data.zipCode,
          zipcodeIsValid: data.zipcodeIsValid,
          insuranceProvider: data.insuranceProvider,
          deductible: data.deductible,
          isDeductibleMet: data.isDeductibleMet,
          deductiblePercentage: data.deductiblePercentage,
          oopMax: data.oopMax,
          isMaxOopMet: data.isMaxOopMet,
          oopMaxPercentage: data.oopMaxPercentage,
          offset: data.offset,
          limit: data.limit,
          totalFacilities: data.totalFacilities,
          sort: data.sort,
          distance: data.distance,
          servicesList: data.servicesList,
          distanceList: data.distanceList,
          facilitiesList: data.facilitiesList,
          serviceID: data.serviceID,
          preferredFacilities: data.preferredFacilities,
          listOfSavedEstimates: data.listOfSavedEstimates,
          loadedServiceData: data.loadedServiceData,
          isCashDiscountActive: data.isCashDiscountActive,
          preferredFilterActive: data.preferredFilterActive,
        },
      });
    }
  };

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleChange = (event) => {
    const { name, value } = event.target;
    data[name] = value;

    if (name == "deductiblePercentage") {
      if (value == 1) {
        data.isDeductibleMet = true;
      } else {
        data.isDeductibleMet = false;
      }

      var newLowestValue = calculateOopMaxPercentage(data.oopMax);

      setLowestOopValue(newLowestValue);

      if (data.oopMaxPercentage < newLowestValue) {
        data.oopMaxPercentage = newLowestValue;
      }
    } else if (name == "oopMaxPercentage") {
      if (value == 1) {
        data.isMaxOopMet = true;
      } else {
        data.isMaxOopMet = false;
      }
    }

    setData(data);
    // getFacilitiesList();
    forceUpdate();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const changeUpdatedCoverage = () => {
    setUpdatedCoverage(false);
  };

  const toggleCoverageDetails = () => {
    if (showCoverageForm) {
      setShowCoverageForm(false);
    } else {
      setShowCoverageForm(true);
    }
  };

  function getDetail(name) {
    if (data.facilitiesList != null) {
      const facility =
        data.facilitiesList.find((element) => element.name == name) ??
        data.preferredFacilities.find((element) => element.name == name) ??
        data.listOfSavedEstimates.find((element) => element.name == name);
      return facility.detail;
    }
  }

  function calculateOopMaxPercentage(value) {
    const deductible =
      data.deductible == "I don't know" ? 2000 : data.deductible;
    const deductiblePercentage =
      data.isDeductibleMet == "true" ? 1 : data.deductiblePercentage;

    const deductiblePaid =
      deductiblePercentage == 0 ? 0 : deductible * deductiblePercentage;
    const percentageOfOopPaid =
      deductiblePaid == 0 ? deductiblePaid : deductiblePaid / value;
    const roundedPercentage = Math.round(percentageOfOopPaid * 10) / 10;

    return roundedPercentage;
  }
  const StarRating = (props) => {
    return (
      <div className="star-rating">
        {[...Array(5)].map((item, idx) => {
          return (
            <img
              key={idx}
              className="star"
              src={idx < props.rating ? filledStar : star}
            />
          );
        })}
      </div>
    );
  };

  function capitalize(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  const Tags = () => {
    const facilityDetail =
      data?.facilityDetail ?? location.state?.apptmt?.facility_detail;
    const insuranceProvider =
      data?.insuranceProvider ??
      location.state?.apptmt?.insurance_provider?.[0];
    if (loaded) {
      const tags = [
        facilityDetail ? capitalize(facilityDetail) : null,
        "Covered service",
        `${insuranceProvider ? "Accepts " + insuranceProvider : ""}`,
        "Copay at appt",
      ];
      return (
        <div
          style={
            !smallScreen
              ? {
                  display: "flex",
                  gap: "36px",
                  marginTop: "41px",
                  marginBottom: "48px",
                }
              : { marginTop: "41px", marginBottom: "48px" }
          }
        >
          {tags.map((tag, idx) =>
            tag ? (
              <div className="tag-container" key={tag}>
                <img src={checkMark} alt="check mark" />
                <p className="tag-text">{tag}</p>
              </div>
            ) : null
          )}
        </div>
      );
    }
  };
  var EstimateCallout = () => {
    if (loaded) {
      const detail = data && getDetail(data.facility);

      let detailElement;
      if (detail) {
        detailElement = (
          <p className="estimate__estimate-callout-facility-detail">{detail}</p>
        );
      } else {
        detailElement = null;
      }

      function goToBooking() {
        history.push({
          pathname: "/booking",
          state: {
            prevPath: location.pathname,
            service: data?.service ?? location.state.apptmt?.service,
            serviceName:
              data?.serviceName ?? location.state.apptmt?.service.display_name,
            zipCode: data?.zipCode,
            insuranceProvider: data?.insuranceProvider,
            serviceID: data?.serviceID ?? location.state.apptmt?.service.id,
            facility: location.state.apptmt?.facility ?? facility,
            quote: location.state.apptmt?.cost_data ?? quote,
            data: data && data,
            apptmt: location.state.apptmt && location.state.apptmt,
            serviceCostID:
              location.state.apptmt && location.state.apptmt?.service_cost_id,
          },
        });
      }
      return (
        <div
          className="facility-estimate__estimate-callout"
          id="estimate-callout"
          ref={(el) => {
            estimateDetailsRef.current = el;
          }}
        >
          <div
            style={smallScreen ? { marginTop: "8rem" } : null}
            className="width-896"
          >
            <Button
              iconFirst
              class="text-btn"
              className="mobile-search__close"
              icon={<img style={{ marginRight: "11px" }} src={left} />}
              label={`${location.state.apptmt ? " Appointments" : " Search"}`}
              onClick={() => onClick()}
              active={true}
            />
            <p
              style={{ paddingBottom: "0px", marginBottom: "0px" }}
              className="facility-estimate__callout-main-text"
              id="callout-main-text"
            >
              {location.state.apptmt?.service.display_name ?? data.serviceName}
            </p>
            <p
              style={{ paddingBottom: "0px" }}
              className="facility-estimate__callout-main-text"
              id="callout-main-text"
            >
              {location.state.apptmt?.cost_data.user_cost ?? quote.user_cost}
            </p>

            <p className="facility-estimate__callout-facility-name">
              {location.state.apptmt?.facility.facility_name ?? data.facility}
            </p>
            <p className="facility-estimate__callout-facility-address">
              {location.state.apptmt?.cost_data.address1 ?? quote.address1}
            </p>
            <p className="facility-estimate__callout-facility-address">
              {location.state.apptmt?.cost_data.address2 ?? quote.address2}
            </p>

            {location.state.apptmt?.facility.cms_quality_rating ??
            facility.cms_quality_rating ? (
              <StarRating
                rating={
                  location.state.apptmt?.facility.cms_quality_rating ??
                  facility?.cms_quality_rating
                }
              />
            ) : (
              <p className="quality-rating-unavailable-text">
                Rating unavailable
              </p>
            )}
            <Tags />
            <Button
              active={true}
              onClick={() => goToBooking()}
              class="long-btn filled"
              id="book-service-btn"
              label="book service"
            />
            <div
              onClick={() =>
                estimateSaved ? removeEstimate() : saveEstimate()
              }
              className="save-for-later-container"
            >
              <img
                src={estimateSaved ? filledHeart : heart}
                alt="save for later"
              />
              <Button
                active={true}
                className="save-for-later-btn"
                class="text-btn"
                label={estimateSaved ? "Saved estimate" : "save for later"}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const changeFacilityHandler = (facility) => {
    const newData = {
      service: data.service,
      serviceName: data.serviceName,
      zipCode: data.zipCode,
      zipcodeIsValid: data.zipcodeIsValid,
      insuranceProvider: data.insuranceProvider,
      deductible: data.deductible,
      isDeductibleMet: data.isDeductibleMet,
      deductiblePercentage: data.isDeductibleMet === true ? 1 : 0,
      oopMax: data.oopMax,
      isMaxOopMet: data.isMaxOOPMet,
      oopMaxPercentage: data.oopMaxPercentage,
      facility: facility.name,
      facilityDetail: facility.detail,
      grossCost: facility.user_cost,
      offset: data.offset,
      limit: data.limit,
      sort: data.sort,
      distance: data.distance,
      servicesList: data.servicesList,
      distanceList: data.distanceList,
      facilitiesList: data.facilitiesList,
      serviceID: data.serviceID,
      serviceCostID: facility.service_cost_id,
      userID: data.userID,
      listOfSavedEstimates: data.listOfSavedEstimates,
      preferredFacilities: data.preferredFacilities,
      isCashDiscountActive: data.isCashDiscountActive,
      preferredFilterActive: data.preferredFilterActive,
      totalFacilities: data.totalFacilities,
      loadedServiceData: data.loadedServiceData,
      id: facility.id,
    };

    setData(newData);
    setContentUpdated(true);
    setEstimateSaved(false);
  };
  const MoreFacilities = () => {
    if (
      !location.state.apptmt &&
      loaded &&
      data.facilitiesList != null &&
      data.facilitiesList.length > 1
    ) {
      const moreFacilities = [];
      data.facilitiesList
        .filter((facility) => facility.name != data.facility)
        .slice(0, 3)
        .map((item) => moreFacilities.push(item));

      return (
        <div
          ref={(el) => {
            otherEstimatesRef.current = el;
          }}
          style={{ marginBottom: "0px" }}
          className="facility-estimate__estimate-section"
          id="other-estimates"
        >
          <div style={{ maxWidth: "896px", margin: "auto" }}>
            <p className="question-section__overline">Other nearby estimates</p>
            {moreFacilities.map((facility, idx) => (
              <div
                key={idx}
                onClick={() => changeFacilityHandler(facility)}
                className="other-facilities-container"
              >
                <div
                  style={{ paddingTop: "0px" }}
                  className="card-result"
                  key={idx}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="card__detail-distance">
                      {facility.distance.toFixed(2)} miles away
                    </p>
                    <p className="card__detail-name">{facility.name}</p>
                    <p className="card__detail-tag">{facility.user_cost}</p>
                  </div>

                  <div style={{ display: "flex" }}>
                    {facility.detail ? (
                      <p className="results__container-tag">
                        {facility.detail}
                      </p>
                    ) : null}
                    <img src={right} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  var CostComparison = () => {
    const QualityRating = () => {
      return (
        <div
          className="facility-estimate__estimate-section"
          ref={(el) => {
            qualityRatingsRef.current = el;
          }}
          id="quality-ratings"
          style={smallScreen ? { padding: "16px", marginBottom: "0px" } : null}
        >
          <div className="width-896">
            <p className="quality-heading">How quality compares</p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="width-368">
                <p
                  className={
                    smallScreen
                      ? "facility-estimate__secondary-text"
                      : "facility-estimate__text-bold"
                  }
                >
                  Overall rating
                </p>
                <p
                  className="facility-estimate__text-bold"
                  style={{ marginBottom: "0px" }}
                >
                  {location.state.apptmt?.facility.cms_quality_rating ??
                    facility.cms_quality_rating}{" "}
                  out of 5
                </p>
                <StarRating
                  rating={
                    location.state.apptmt?.facility.cms_quality_rating ??
                    facility.cms_quality_rating
                  }
                />
              </div>

              {!smallScreen ? (
                <p className="ratings-description">
                  The{" "}
                  <a
                    className="rating-detail-anchor"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.medicare.gov/care-compare/resources/hospital/overall-star-rating"
                  >
                    Medicare overall star rating{" "}
                  </a>
                  is based on how well a hospital performs across different
                  areas of quality, such as treating heart attacks and
                  pneumonia, readmission rates, and safety of care.
                </p>
              ) : null}
            </div>

            <br />

            {(location.state.apptmt?.facility.patient_survey_rating ??
              facility.patient_survey_rating) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="width-368">
                  <p
                    className={
                      smallScreen
                        ? "facility-estimate__secondary-text"
                        : "facility-estimate__text-bold"
                    }
                  >
                    Patient survey rating
                  </p>
                  <p
                    className="facility-estimate__text-bold"
                    style={{ marginBottom: "0px" }}
                  >
                    {location.state.apptmt?.facility.patient_survey_rating ??
                      facility.patient_survey_rating}{" "}
                    out of 5
                  </p>
                  <StarRating
                    rating={
                      location.state.apptmt?.facility.patient_survey_rating ??
                      facility.patient_survey_rating
                    }
                  />
                </div>
                {!smallScreen ? (
                  <p
                    style={{ paddingTop: "70px" }}
                    className="ratings-description"
                  >
                    The{" "}
                    <a
                      className="rating-detail-anchor"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.medicare.gov/care-compare/resources/hospital/patient-survey-rating"
                    >
                      Medicare patient survey rating
                    </a>{" "}
                    measures patients' experiences of their hospital care.
                    Recently discharged patients were asked about important
                    topics like how well nurses and doctors communicated, how
                    responsive hospital staff were to their needs, and the
                    cleanliness and quietness of the hospital environment.
                  </p>
                ) : null}
              </div>
            )}
            <br />
            {smallScreen && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.medicare.gov/care-compare/#search"
                style={{ paddingBottom: "46px", paddingTop: "32px" }}
                className="text-btn-link"
              >
                see ratings detail
              </a>
            )}
          </div>
        </div>
      );
    };
    return !smallScreen ? (
      <>
        {(location.state.apptmt?.facility.cms_quality_rating ??
          facility.cms_quality_rating) ||
        (location.state.apptmt?.facility.patient_survey_rating ??
          facility.patient_survey_rating)
          ? QualityRating()
          : null}
      </>
    ) : (
      <div
        className="facility-page__section facility-page__cost-comparison-section"
        id="cost-section"
        ref={costRef}
      >
        <div className="facility-page__max-width">
          {(location.state.apptmt?.facility.cms_quality_rating ??
            facility.cms_quality_rating) ||
          (location.state.apptmt?.facility.patient_survey_rating ??
            facility.patient_survey_rating)
            ? QualityRating()
            : null}
        </div>
      </div>
    );
  };

  var SaveEstimatePopup = () => {
    return (
      <PopupModal
        size="lg"
        position="centered"
        show={showModal}
        close={closeModal}
        body={
          <div className="save-your-estimate-modal">
            <div id="save-estimate-modal-content">
              <p className="save-estimate-heading">Save your estimate.</p>
              <p className="save-estimate-text">
                Want to save this estimate for your records? Enter your first
                name and email address and we’ll send you a copy of the cost
                estimate. Don’t worry, we won’t share or save your information,
                and we won’t spam you!
              </p>
              <SendEstimate
                closeModal={closeModal}
                smallScreen={smallScreen}
                hasInsurance={hasInsurance}
              />
            </div>
            <div id="share-feedback-container">
              <ShareFeedback closeModal={closeModal} />
            </div>
          </div>
        }
      />
    );
  };
  const EstimateDetailsNavigation = () => {
    const positionList = [
      "Cost and location",
      ...((location.state.apptmt?.facility.cms_quality_rating ??
        facility.cms_quality_rating) ||
      (location.state.apptmt?.facility.patient_survey_rating ??
        facility.patient_survey_rating)
        ? ["Quality ratings"]
        : []),
      "Service details",
      "Other estimates",
    ];
    const positionListDesktop = [
      "Estimate details",
      "Cost and location",
      ...((location.state.apptmt?.facility.cms_quality_rating ??
        facility.cms_quality_rating) ||
      (location.state.apptmt?.facility.patient_survey_rating ??
        facility.patient_survey_rating)
        ? ["Quality ratings"]
        : []),
      "Service details",
      ...(data?.facilitiesList.length > 1 ? ["Other estimates"] : []),
    ];

    const scrollTo = (ref) => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    function positionChangeHandler(e) {
      e.preventDefault();
      setCurrentPosition(e.target.value);
      if (
        e.target.value === "Estimate details" ||
        e.target.innerHTML === "Estimate details"
      ) {
        scrollTo(estimateDetailsRef);
      }

      if (
        e.target.value === "Cost and location" ||
        e.target.innerHTML === "Cost and location"
      ) {
        scrollTo(costAndLocationRef);
      }

      if (
        e.target.value === "Quality ratings" ||
        e.target.innerHTML === "Quality ratings"
      ) {
        scrollTo(qualityRatingsRef);
      }

      if (
        e.target.value === "Service details" ||
        e.target.innerHTML === "Service details"
      ) {
        scrollTo(serviceDetailsRef);
      }

      if (
        e.target.value === "Other estimates" ||
        e.target.innerHTML === "Other estimates"
      ) {
        scrollTo(otherEstimatesRef);
      }
    }
    return smallScreen ? (
      <div style={{ marginTop: "3.75rem" }} className="mobile-navigator">
        <select
          value={currentPosition}
          onChange={(e) => positionChangeHandler(e)}
        >
          {positionListDesktop.map((position, idx) => (
            <option key={position}>{position}</option>
          ))}
        </select>
      </div>
    ) : (
      <ul className="desktop-navigator">
        {positionListDesktop.map((position) => (
          <li
            className={
              position === currentPosition ? "desktop-navigator-selected" : null
            }
            key={position}
            onClick={(e) => {
              positionChangeHandler(e);
            }}
          >
            {position}
          </li>
        ))}
      </ul>
    );
  };

  if (!loaded) {
    return <Loading />;
  } else {
    if (smallScreen) {
      return (
        <Mobile
          body={
            <div>
              <div className="estimate" id="estimate-page">
                {EstimateDetailsNavigation()}
                {SaveEstimatePopup()}
                {EstimateCallout()}
                <div className="facility-estimate__container">
                  {
                    <UnderstandingYourEstimate
                      facilityName={
                        location.state.apptmt?.facility.facility_name
                      }
                      serviceName={location.state.apptmt?.service.display_name}
                      hasInsurance={hasInsurance}
                      smallScreen={smallScreen}
                      quote={location.state.apptmt?.cost_data ?? quote}
                      costAndLocationRef={costAndLocationRef}
                      data={data}
                      loaded={loaded}
                    />
                  }
                  {
                    <WhereYoullGo
                      smallScreen={smallScreen}
                      quote={location.state.apptmt?.cost_data ?? quote}
                      facility={location.state.apptmt?.facility ?? facility}
                      loaded={loaded}
                    />
                  }
                  {CostComparison()}
                  {
                    <ServiceDetailSection
                      service={location.state.apptmt?.service ?? service}
                      serviceDetailsRef={serviceDetailsRef}
                    />
                  }
                  {MoreFacilities()}
                  {<Footer />}
                </div>
              </div>
            </div>
          }
          back={onClick}
          lastPage="FACILITIES"
          classes="p-0"
          // quoteNavClasses='dark-background px-5'
          quoteNavTextClasses="text-white"
          arrowClasses="white-arrow"
          // showQuoteNavigation={true}
          showMobileNav={true}
          mobileNavColor="dark"
        />
      );
    } else {
      return (
        <Desktop
          showProgress={false}
          body={
            <div>
              <div className="estimate" id="estimate-page">
                {EstimateDetailsNavigation()}
                {SaveEstimatePopup()}
                {EstimateCallout()}
                <div className="facility-estimate__container">
                  {
                    <UnderstandingYourEstimate
                      hasInsurance={hasInsurance}
                      smallScreen={smallScreen}
                      quote={location.state.apptmt?.cost_data ?? quote}
                      costAndLocationRef={costAndLocationRef}
                      data={data}
                      loaded={loaded}
                    />
                  }
                  {
                    <WhereYoullGo
                      smallScreen={smallScreen}
                      quote={location.state.apptmt?.cost_data ?? quote}
                      facility={location.state.apptmt?.facility ?? facility}
                      loaded={loaded}
                    />
                  }
                  {CostComparison()}
                  {
                    <ServiceDetailSection
                      service={location.state.apptmt?.service ?? service}
                      serviceDetailsRef={serviceDetailsRef}
                    />
                  }
                  {MoreFacilities()}
                </div>
              </div>
            </div>
          }
        />
      );
    }
  }
}

export default FacilityEstimate;
