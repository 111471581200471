import { CostEstimator } from "./CostEstimator";
import "./CostEstimator.css";
import right from "../../assets/icons/right.svg";
import React, { useState } from "react";
import EstimateDetails from "./EstimateDetails";
import { extractFeeText } from "./utils";

function totalCostText(estimate) {
  if (estimate?.total_combined_cost?.out_of_pocket_cost?.human_readable != null) {
    return estimate?.total_combined_cost?.out_of_pocket_cost?.human_readable;
  } else if (estimate?.institutional_info?.institutional_fee?.total_cost?.negotiated_type == 'percentage' || estimate?.professional_info?.professional_fee?.total_cost?.negotiated_type == 'percentage') {
    return 'Rate varies by provider charges';
  } else if (estimate?.institutional_info?.institutional_fee?.total_cost?.human_readable != null || estimate?.professional_info?.professional_fee?.total_cost?.human_readable != null) {
    return 'Total rate unavailable';
  } else {
    return 'Rate unavailable';
  }
}

function EstimateCard({
  estimate,
  customRef,
  setSelectedEstimate,
  selectedEstimate,
  cursor,
  renderOONTags
}) {
  const isOutOfNetworkProvider =
    estimate?.professional_info.out_of_network_provider;
  const isOutOfNetworkInstitution =
    estimate?.institutional_info.out_of_network_facility;

  function renderEstimateComponent(info) {
    return (info.should_render === true && info.details.display !== null) || info.details.display !== null;
  }

  function handleEstimateSelection(value) {
    // setSelectedEstimate is null if we're temporarily
    // limiting the ability to see the second page of estimates
    if (!setSelectedEstimate) return;
    setSelectedEstimate(value);
  }

  return (
    <div
      ref={customRef !== null ? customRef : null}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F1F1F1",
        cursor: "pointer",
      }}
      className="page-result"
      //theSetSelectedEst is going to be representing the back click, if its null then we go back to the results page otherwise if its true we stay
      onClick={() => handleEstimateSelection(estimate)}
    >
      <div className="cost-card-result">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            gap: "1px",
          }}
        >
          <div>
            <div
              className="provider"
              style={{
                display: "flex",
              }}
            >
              <div className="card_detail-name">
                {estimate.distance !== undefined && (
                  <p className="margin-bottom-4 text-small">
                    {estimate.distance} miles away
                  </p>
                )}
                {renderEstimateComponent(estimate.professional_info) && (
                  <>
                  <p className="card__detail-name margin-bottom-4">
                  Dr. {estimate.professional_info.details.display}
                  </p>

                  <p className="card-secondary-info">
                  {" "}
                  {estimate.professional_info.details.specialties[0]?.display}
                  {" • "}
                  {extractFeeText(
                    estimate?.professional_info?.professional_fee
                  )}
                  </p>
                  </>
            
                )}
              
              </div>
            </div>
            {renderEstimateComponent(estimate.institutional_info) && (
              <div className="institution" style={{ display: "flex" }}>
                <div>
                  <p className="card__detail-name margin-bottom-4">
                  {estimate.institutional_info.details.display}
                  </p>

                  <p className="card-secondary-info">
                    {" "}
                    {
                      estimate.institutional_info.details.place_of_service
                        ?.display
                    }
                    {" • "}{" "}
                    {extractFeeText(
                      estimate?.institutional_info?.institutional_fee
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="cost-results__container-right">
          <div className="flex-col" style={{ gap: "36px" }}>
            {isOutOfNetworkProvider && renderOONTags && (
              <p className="cost-results__container-tag"> OUT OF NETWORK</p>
            )}

            {isOutOfNetworkInstitution && renderOONTags && renderEstimateComponent(estimate.institutional_info) && (
              <p className="cost-results__container-tag"> OUT OF NETWORK</p>
            )}
          </div>

          <div style={{ display: "flex", columnGap: "25px", alignItems: "baseline" }}>
            <p
              className={`total-cost-tag ${
                estimate?.total_combined_cost?.out_of_pocket_cost?.human_readable == null
                  ? "not-available-cost-tag"
                  : ""
              }`}
            >
              {totalCostText(estimate)}
            </p>
            <img src={right} />
            {estimate.metadata?.affiliation_type != null && (
  <p className="cost-results__container-tag">{estimate.metadata?.affiliation_type}</p>
)}
            {estimate?.institutional_info?.institutional_fee?.billing_class != null 
            && 
            <p className="cost-results__container-tag">{estimate?.institutional_info?.institutional_fee?.billing_class}</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstimateCard;
