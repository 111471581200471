import axios from 'axios';
import Button from '../../components/elements/Button';
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";

function DeletionActions(props) {
  const history = useHistory();
  const [hipaaConsented, setHipaaConsented] = useState(false);

  useEffect(() => {
    checkConsent();
  }, [props.userId]);

  function checkConsent() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users/' + props.userId + '/check_consent';
    axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    })
    .then(res => {
      if (res.status == 200) {
        setHipaaConsented(res.data.data.attributes.hipaa_consented);
      } else {
        // Error
        console.log(res);
      }
    }).catch(function(error) {
      // Error
      console.log(error.response);
    });
  }

  function deleteAccount() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users/' + props.userId;
    axios.delete(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    })
    .then(res => {
      if (res.status == 200) {
        console.log(res);
        history.push({
          pathname: "/sign_in" });
        window.scrollTo(0, 0);
      } else {
        // Error
        console.log(res);
      }
    }).catch(function(error) {
      // Error
      console.log(error.response);
    });
  }

  const DeleteAccountElement = () => {
    return(
      <div className='settings__deletion-section'>
        <p>Delete your account will remove all account history and data, and will be permananent blah blah blah.</p>
        <Button 
          label="Delete My Account"
          active={true}
          class="long-btn text-btn"
          onClick={() => deleteAccount()}
        />
      </div>
    );
  }

  function updateConsent() {
    var updateRequest = {hipaa_consented: !hipaaConsented} ;

    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users/' + props.userId;
    axios.patch(URL, { user: updateRequest }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    })
    .then(res => {
      if (res.status == 200) {
        setHipaaConsented(res.data.data.attributes.hipaa_consented);
      } else {
        // Error
        console.log(res);
      }
    }).catch(function(error) {
      // Error
      console.log(error.response);
    });
  }

  const HipaaConsentElement = () => {
    var buttonLabel = hipaaConsented == true ? "Revoke My Hippa Consent" : "Invoke My Hippa Consent"
    return (
      <div className='settings__deletion-section'>
        <p>Your health data and whatever about HIPPAA consent beepbopboop delete your stuff</p>
        <Button 
          label={buttonLabel}
          active={true}
          class="long-btn text-btn"
          onClick={() => updateConsent()}
        />
      </div>
    )
  }

  return (
    <div className='settings__deletions'>
      {DeleteAccountElement()}
      {HipaaConsentElement()}
      
    </div>
  )

}

export default DeletionActions;
