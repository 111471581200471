import './PriceBars.css'

function PriceBars(props) {
  // PROPS
  // serviceMinCashPrice 
  // serviceMaxCashPrice
  // serviceMinInsuredPrice
  // serviceMaxInsuredPrice

  // Currancy formatter
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  

  let minCash;
  let maxCash;
  let minInsured;
  let maxInsured;
  
  if (props.serviceMaxCashPrice < props.serviceMaxInsuredPrice) {
    maxInsured = 100;
    maxCash = Math.ceil((props.serviceMaxCashPrice / props.serviceMaxInsuredPrice) * 100 );
    minInsured = Math.ceil((props.serviceMinInsuredPrice / props.serviceMaxInsuredPrice) * 100);
    minCash = Math.ceil((props.serviceMinCashPrice / props.serviceMaxInsuredPrice) * 100);
  } else {
    maxCash = 100;
    maxInsured = Math.ceil((props.serviceMaxInsuredPrice / props.serviceMaxCashPrice) * 100);
    minInsured = Math.ceil((props.serviceMinInsuredPrice / props.serviceMaxCashPrice) * 100);
    minCash = Math.ceil((props.serviceMinCashPrice / props.serviceMaxCashPrice) * 100);
  }

  return (
    <div>
        <p className='pricebars-label'>Without insurance</p>
      <div className='service-page__cash-price-bars'>
          <div className='service-page__bar min-bar service-page__min-cash-price' style={{width: minCash + '%'}}>
            <div className='service-page__antimated-bar'>
              <p className='service-page__price-text'>{formatter.format(props.serviceMinCashPrice)}</p>
            </div>
            
          </div>
          <div className='service-page__bar max-bar service-page__max-cash-price' style={{width: maxCash + '%'}}>
            <div className='service-page__antimated-bar'>
              <p className='service-page__price-text'>{formatter.format(props.serviceMaxCashPrice)}</p>
            </div>
          </div>
        </div>

        <p className='pricebars-label'>With insurance</p>
        <div className='service-page__insured-price-bars'>

          <div className='service-page__bar min-bar service-page__bar min-bar service-page__min-insured-price' style={{width: minInsured + '%'}}>
            <div className='service-page__antimated-bar'>
              <p className='service-page__price-text'>{formatter.format(props.serviceMinInsuredPrice)}</p>
            </div>
            
          </div>
          <div className='service-page__bar max-bar service-page__bar max-bar service-page__max-insured-price' style={{width: 100 + '%'}}>
            <div className='service-page__antimated-bar'>
              <p className='service-page__price-text'>{formatter.format(props.serviceMaxInsuredPrice)}</p>
            </div>
          </div>
          
        </div>
    </div>
  );

}

export default PriceBars;
