import './Button.css';

function Button(props) {
  const className = `button ${props.class} ${props.className} ${props.active ? 'active': 'disabled'} `
  
  if (props.hide) {
    return (<div className='mb-5 pb-3'> </div>);
  } else {
    return (
      <button 
        type={props.type} 
        form={props.form}
        id={props.id} 
        className={className} 
        onClick={props.onClick} 
        disabled={!props.active} >
        {props.iconFirst ? 
        <>
        {props.icon}
        {props.label} 
        </>
        : 
        <>
        {props.label}
        {props.icon} 
        </>
        }
      </button>
    );
  }

  
}

 export default Button
 