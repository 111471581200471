import './AboutUs.css'
import Desktop from '../../components/templates/Desktop'
import Mobile from '../../components/templates/Mobile'
import React, { useEffect } from "react";

function AboutUs() {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
    }
  })

  React.useEffect(() => {       window.scrollTo(0, 0);     }, []);

  const aboutUsBody= (
    <div className='about-us'>
      <div className='about-us--1'>
        <div className='about-us-heading-container'>
          <p className='about-us-heading'>We’re Handl Health</p>
        </div>
        <div className='about-us-text'>
          <p className="">Handl Health was founded in 2020 by two friends who were tired of dealing with surprise medical bills and the anxiety of not knowing how much they’d pay for care - wishing they had someone who could just “handle” it.</p>
          <p className="">They put their heads together, and with a skilled team of digital health experts, design strategists, data scientists and software developers, Handl Health was officially born. </p>
        </div>
      </div>
      <div className='about-us--2'>
        <section>
          <p className='about-us-heading'>Price Transparency at its finest.</p>
          <p className="text-large">Handl Health provides accurate prices of hospital services, allowing you to search for the care you need and compare prices at different locations. </p>
        </section>
      </div>
      <div className='about-us--3'>
        <section>
          <p className='about-us-heading'>Our mission is to make shopping for healthcare the new norm — empowering <em>everyone</em> to make informed decisions about the care they need.  </p>
        </section>
      </div>
    </div>
  )


  if (window.innerWidth <= 768) {
    return (
      <Mobile 
      body={aboutUsBody}
      showQuoteNavigation={false} 
      showMobileNav={true} 
      mobileNavColor='white'
    />
      
    );
  } else {
    return (
      <Desktop
        body={aboutUsBody}
      />
    );
  }
}

export default AboutUs;
