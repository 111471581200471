import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Contact from "./Contact/Contact.js";
import AboutUs from "./AboutUs/AboutUs.js";
import Glossary from "./Glossary/Glossary.js";
import Search from "./MobileSearch/Search.js";
import Articles from "./Articles/Articles.js";
import PressReleases from "./PressReleases/PressReleases.js";
import SignUp from "./SignUp/SignUp.js";
import SignIn from "./SignIn/SignIn.js";
import ForgotPassword from "./SignIn/ForgotPassword";
import Onboarding from "./Onboarding/Onboarding.js";
import UnlockAccount from "./SignIn/UnlockAccount";
import Settings from "./Settings/Settings.js";
import useToken from "../hooks/useToken";
import PrivateRoute from "./PrivateRoute.js";
import HomeFeed from "./Home/HomeFeed.js";
import FacilityEstimate from "./Search/FacilityEstimate.js";
import ScrollToTop from "../ScrollToTop.js";
import CostEstimator from "./CostEstimator/CostEstimator.js";
import FancySearch from "./Search/FancySearch.js";

function Directory() {
  const { setToken } = useToken();

  useEffect(() => {
    document.title = "Handl Health";
  }, []);

  return (
    <div className="h-100">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <PrivateRoute path="/" component={HomeFeed} />}
          />
          {/* <Route exact path="/fancy_search" component={FancySearch} /> */}
          <Route exact path="/facility_estimate" component={FacilityEstimate} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about_us" component={AboutUs} />
          <Route exact path="/glossary" component={Glossary} />
          <Route exact path="/search" component={Search} />
          <Route path="/articles" component={Articles} />
          <Route exact path="/press_releases" component={PressReleases} />
          {/* <Route exact path="/booking" component={Booking} /> */}
          <Route exact path="/sign_up" component={SignUp} />
          <Route exact path="/onboarding" component={Onboarding} />
          <Route
            exact
            path="/sign_in"
            component={() => <SignIn setToken={setToken} />}
          />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/unlock_account" component={UnlockAccount} />
          <Route
            path="/settings"
            render={(props) => (
              <PrivateRoute path="/settings" component={Settings} />
            )}
          />
          <Route
            exact
            path="/cost-estimator"
            render={(props) => (
              <PrivateRoute path="/cost-estimator" component={CostEstimator} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Directory;
