import React, { useState, useEffect } from "react";
import ToggleSwitch from "../../components/elements/ToggleSwitch";
import axios from "axios";
import Button from "../../components/elements/Button";

function StepFour(props) {
  const userNotificationPreferences = props.userNotificationPreferences;
  const setUserNotificationPreferences = props.setUserNotificationPreferences;

  const token = props.token;
  const userID = props.userID;

  // update notifications preferences
  function updateUserNotificationPreferences(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_notification_preferences/" +
      userID;
    axios
      .patch(
        URL,
        { user_notification_preference: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          props.switchPage("stepFive");
          props.setProgressPercentage(100);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function handleChange(e) {
    setUserNotificationPreferences({
      ...userNotificationPreferences,
      [e.target.name]: e.target.checked,
    });
  }

  function submitPreferencesHandler() {
    const updateRequest = {
      appointment_text: userNotificationPreferences.appointment_text,
      appointment_email: userNotificationPreferences.appointment_email,
      appointment_secondary_email:
        userNotificationPreferences.appointment_secondary_email,
      billing_text: userNotificationPreferences.billing_text,
      billing_email: userNotificationPreferences.billing_email,
      billing_secondary_email:
        userNotificationPreferences.billing_secondary_email,
      marketing_text: userNotificationPreferences.marketing_text,
      marketing_email: userNotificationPreferences.marketing_email,
      marketing_secondary_email:
        userNotificationPreferences.marketing_secondary_email,
    };

    updateUserNotificationPreferences(updateRequest);
  }

  return (
    <>
      <p
        className="body-2 pointer"
        onClick={() => {
          props.switchPage("stepThree");
          props.setProgressPercentage(60);
        }}
      >
        <span className="back-arrow" /> STEP 3
      </p>
      <h2 className="onboarding__landing-header-secondary width-694">
        How should we contact you?
      </h2>
      <div className="onboarding__textbox-primary">
        <p className="body-1">
          You can always update these preferences in your account.
        </p>
        <div className="onboarding__notifications-content-box">
          <div className="settings__page-section">
            <p className="onboarding__notifications-subtitle">Appointments</p>
            <p className="body-1 notifications-subtitle">
              Communicate with me about appointments using the following.
            </p>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Text message"
                name="appointment_text"
                checked={userNotificationPreferences.appointment_text}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Work email"
                name="appointment_email"
                checked={userNotificationPreferences.appointment_email}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Personal email"
                name="appointment_secondary_email"
                checked={
                  userNotificationPreferences.appointment_secondary_email
                }
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="settings__page-section">
            <p className="onboarding__notifications-subtitle">Billing</p>
            <p className="body-1 notifications-subtitle">
              Communicate with me about bills using the following.
            </p>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Text message"
                name="billing_text"
                checked={userNotificationPreferences.billing_text}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Work email"
                name="billing_email"
                checked={userNotificationPreferences.billing_email}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Personal email"
                name="billing_secondary_email"
                checked={userNotificationPreferences.billing_secondary_email}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="settings__page-section">
            <p className="onboarding__notifications-subtitle">Handl updates</p>
            <p className="body-1 notifications-subtitle">
              We’re adding new features every month. Stay up to date with what
              you can do here.
            </p>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                disabled={props.phoneNumber === ""}
                label="Text message"
                name="marketing_text"
                checked={userNotificationPreferences.marketing_text}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                label="Work email"
                name="marketing_email"
                checked={userNotificationPreferences.marketing_email}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="onboarding__notification-option">
              <ToggleSwitch
                disabled={props.personalEmail === ""}
                label="Personal email"
                name="marketing_secondary_email"
                checked={userNotificationPreferences.marketing_secondary_email}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <Button
          label="complete profile setup"
          onClick={() => submitPreferencesHandler()}
          active={true}
          class="long-btn filled"
        />
      </div>
    </>
  );
}

export default StepFour;
