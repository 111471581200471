import React from 'react'
import locationIcon from "../../assets/icons/ep_location.svg"
import phone from "../../assets/icons/phone.svg"
import hours from "../../assets/icons/hours.svg"

function WhereYoullGo({appointmentCenter, smallScreen, loaded, quote, facility, past=false}) {
        if (loaded) {
          var facilityAddress = quote.facility_address;
          var map_url = "https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY + "&q=" + facilityAddress;
    
    
          return (
            <div className={`${(!smallScreen&&appointmentCenter) ? '' : 'facility-estimate__estimate-section'} where-youll-be`}>
              <div className='width-896'>
                <p style={!smallScreen ?{marginBottom:'24px'} : null} className='estimate__estimate-section-title'>Where {past===true ? "you were" : "you'll be"}</p>
                    {facility.network && 
                    <>
                    <p className='facility-estimate__secondary-text'>Network Affiliation</p>
                    <p className='facility-estimate__text-bold'>{facility.network}</p>
                    </>
                    }
                    
                    <p className='facility-estimate__secondary-text'>Facility Type</p>
                    <p className='facility-estimate__text-bold'>{facility.type}</p>
                    
                  <div className='facility-estimate__facility-details'>
                    
                    <iframe
                      width="100%"
                      height="250"
                      style={{marginBottom:'24px'}}
                      loading="lazy"
                      allowFullScreen
                      src={map_url}>
                    </iframe>
                    <div className='facility-estimate__facility-container' style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                      <div className='facility-estimate__facility-box'  style={{display:'flex'}}>
                      <img src={locationIcon}/>
                      <p className='facility-estimate__facility-detail'>{quote.address1}<br/> {quote.address2}</p>
                      </div>
    
                      <div className='facility-estimate__facility-box'  style={{display:'flex'}}>
                      <img src={phone}/>
                      <p className='facility-estimate__facility-detail'>{facility.phone ?? facility.facility_phone}</p>
                      </div>
                      <div className='facility-estimate__facility-box'  style={{display:'flex'}}>
                      <img src={hours}/>
                      <p className='facility-estimate__facility-detail'>Open 24 hours</p>
                     
    
                        </div>
    
                    </div>
                    
                  </div>
              </div>
            </div>
          );
        } 
      }
export default WhereYoullGo