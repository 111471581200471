import React from "react";
import Button from "../../elements/Button";
import { useHistory } from "react-router-dom";

export default function VersatileLinkCard({
  smallScreen,
  title,
  bodyText,
  buttonLabel,
  link,
  state,
  renderPreferredFacilities,
  PreferredFacilityComponent,
}) {
  const history = useHistory();
  return (
    <div className="flex-col home-card versatile-card">
      <p
        style={{ alignSelf: "flex-start" }}
        className={
          smallScreen
            ? "margin-bottom-0"
            : "margin-bottom-0 heading-poppins fw-600"
        }
      >
        {title}
      </p>
      {renderPreferredFacilities === true ? (
        PreferredFacilityComponent()
      ) : (
        <p>{bodyText}</p>
      )}
      <Button
        onClick={() => history.push({ pathname: link, state: state && state })}
        label={buttonLabel}
        active={true}
        class={buttonLabel === "edit" ? "text-btn" : "outline-btn"}
      />
    </div>
  );
}
