import React, {useState, useEffect} from 'react'
import plus from "../../assets/icons/onboarding/plus.svg"
import location from "../../assets/icons/onboarding/location.svg"
import Button from '../../components/elements/Button';
import Input from '../../components/elements/Input';
import Geocode from "react-geocode";
import axios from 'axios';
import success from "../../assets/icons/success-icon.svg"

function StepThree(props) {
    const streetAddress = props.streetAddress
    const zipcode = props.zipcode
    const state = props.state
    const preferredFacilityOne = props.preferredFacilityOne
    const preferredFacilityTwo = props.preferredFacilityTwo
    const preferredFacilityThree = props.preferredFacilityThree
    const preferredIDOne = props.preferredIDOne
    const preferredIDTwo = props.preferredIDTwo
    const preferredIDThree = props.preferredIDThree



    const facilitiesList = props.facilitiesList
    
    const setStreetAddress = props.setStreetAddress
    const setZipcode = props.setZipcode
    const setState = props.setState
    const setPreferredFacilityOne = props.setPreferredFacilityOne
    const setPreferredFacilityTwo = props.setPreferredFacilityTwo
    const setPreferredFacilityThree = props.setPreferredFacilityThree
    const setPreferredIDOne = props.setPreferredIDOne
    const setPreferredIDTwo = props.setPreferredIDTwo
    const setPreferredIDThree = props.setPreferredIDThree

    const userNotificationPreferences = props.userNotificationPreferences
    const setUserNotificationPreferences = props.setUserNotificationPreferences
    
    const token = props.token
    const userID = props.userID
    
    // preferred facilities functionality
    const [showFacilityTwo, setShowFacilityTwo] = useState(false)
    const [showFacilityThree, setShowFacilityThree] = useState(false)
    const [zipcodeUpdated, setZipcodeUpdated] = useState(false)
    
    const [addFacilitiesDisabled, setAddFacilitiesDisabled] = useState(false)

    const [locationServicesText, setLocationServicesText] = useState("Allow location services")
    // this variable is not tied to user preferences, but instead actions taken on just this page
    const [locationEnabled, setLocationEnabled] = useState(false)

    useEffect(() => {
     if (preferredFacilityOne!== '' && preferredFacilityTwo !=='' && preferredFacilityThree !== '') {
      setAddFacilitiesDisabled(true)
     }
    }, [])
    
    function handleAdd() {
      if(preferredFacilityTwo === ''){
        setShowFacilityTwo(true)
      }
      if(preferredFacilityThree === '' && (preferredFacilityTwo !== '' || showFacilityTwo)){
        setShowFacilityThree(true)
        setAddFacilitiesDisabled(true)
      }
    }
    // update user information
    function updateUserInformation(data) {
        const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/' + userID;
        axios.patch(URL, { user_information: data }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(res => { 
            }).catch(function(error) {
              console.log(error.response);
            });
      }

      function updateUserZipCode() {
        const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/update_zip_code';
        axios.patch(URL, { zip_code: zipcode }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(res => { 
              if (res.status == 200) {
                props.switchPage('stepFour')
                props.setProgressPercentage(80)
              } 
            }).catch(function(error) {
              console.log(error.response);
            });
      }

      function updateUserFacility(userfacility, previousPreferredID) {
        if (userfacility === '') {
          const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/update_preferred_facility';
          axios.patch(URL, { preferred_id:previousPreferredID }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }).then(res => { 
                if (res.status == 200) {
                } 
              }).catch(function(error) {
                console.log(error.response);
              });
        } else {
          var facilityId = facilitiesList.find( facility => facility.includes(userfacility))[0];
          const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/update_preferred_facility';
          axios.patch(URL, { facility_id: facilityId, preferred_id:previousPreferredID }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }).then(res => { 
                if (res.status == 200) {

                } 
              }).catch(function(error) {
                console.log(error.response);
              });
        }
      }
       
      // update location service notification preference
      function updateUserNotificationPreferences(data) {
        const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_notification_preferences/' + userID;
        axios.patch(URL, { user_notification_preference: data }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then().catch(function(error) {
          // Error
          console.log(error.response);
        });
      };

    // get location
    function getLocation() {
      Geocode.setLanguage("en");
      Geocode.setRegion("us");
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      
      navigator.geolocation.getCurrentPosition(function(position) {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            const postalCodeQuery = response.results[0].address_components.find(element => element.types[0] == 'postal_code') ?? response.results[1].address_components.find(element => element.types[0] == 'postal_code');
            setZipcode(postalCodeQuery.long_name)
            const state = response.results[0].address_components.find(element => element.types[0] == 'administrative_area_level_1').long_name;
            setState(state)
            setLocationEnabled(true)
            setLocationServicesText("Location services enabled")
          },
          (error) => {
            console.error(error);
          }
        );
      });
    }

    function getLocationHandler() {
      setUserNotificationPreferences({
        ...userNotificationPreferences,
        allow_location_services: true,
      });
      const updateRequest = ({allow_location_services:true});
      updateUserNotificationPreferences(updateRequest)

      getLocation()
    }

    function formHandler(e) {
        e.preventDefault()
          const updateRequest = ({ street_address: streetAddress, state: state });
          updateUserInformation(updateRequest)
 
        if (preferredIDOne !== '' || preferredFacilityOne !== ''){
          updateUserFacility(preferredFacilityOne, preferredIDOne)
        }
        if (preferredIDTwo !== '' || preferredFacilityTwo !== ''){
          updateUserFacility(preferredFacilityTwo, preferredIDTwo)
        }
        if (preferredIDThree !== '' || preferredFacilityThree !== ''){
          updateUserFacility(preferredFacilityThree, preferredIDThree)
        }
        if (zipcode !== ''){
          updateUserZipCode()
        }
      // at this point in time, the zipcode update function is the only function that can respond with an error message, so the ability to progress forwards is linked to zipcode
        else if (zipcode ==='') {
          props.switchPage('stepFour')
          props.setProgressPercentage(80)
        }
        
      }
      const stateList = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming']
      return (
        <>
         <p className='body-2 pointer' onClick={()=>{props.switchPage('stepTwo'); props.setProgressPercentage(40)}}><span className='back-arrow' /> STEP 2</p>
        <h2 className='onboarding__landing-header-secondary' >Add your location details and preferences.</h2>
        <div className='onboarding__textbox-primary'>
        <p className='body-1' >Let us know where you like to get your healthcare! Adding your preferred zip codes and facility networks will allow us to give you results that best fit your needs.</p>
        <form className='onboarding__step1-form' id='onboarding-form-3' noValidate onSubmit={(e)=>formHandler(e)}>
        <p className='text-large txt-fw'>Home address</p>
        <p className='body-1' style={{marginBottom:'54px'}}>Adding your home address will help us give you more accurate results on healthcare facilities nearest to your home.</p>
        <Input
            placeholder="Street address"
            name="street-address"
            label="Street address"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            inputContainerClasses='sign-up-form__input'
            showArrow={false}
            readOnly={false}
          /> 
         <Input
            placeholder="Zip code"
            name="zipcode"
            label="Zip code"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
            inputContainerClasses='sign-up-form__input'
            showArrow={false}
            readOnly={false}
          />   
        <Input
            placeholder="State"
            name="state"
            label="State"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              }}
            onSelect={(val, name) => {
              setState(val)
              }}
            inputContainerClasses='sign-up-form__input'
            showDropdownArrow={true}
            readOnly={false}
            options={stateList.map(i => ({ label: i, value: i }))}
            forceAutocomplete={true}
          /> 
        <p className='text-large txt-fw'>Location services</p>
        <p className='body-1'>Don't want to type in a zip code every time you search for care? Allow location services so we can easily find care wherever you are.</p>
        <button type="button" onClick={()=>getLocationHandler()} className='onboarding__custom-text-button'>
            <div className='flex'>
              <img src={locationEnabled ? success : location}/>
              <p style={{marginBottom:0, marginLeft:'14px'}} className={locationEnabled ? "location-text-enabled" : null}>{locationServicesText}</p>
            </div>
        </button>
        <p className='text-large txt-fw'>Add preferred facility</p>
        <p className='body-1' style={{marginBottom:'54px'}}>You can add up to 3 facilities where you prefer to receive care. You can always update these in your settings.</p>

      
        <Input
        placeholder="Facility name"
        name={`facility-one`}
        label="Facility name"
        value={preferredFacilityOne}
        onChange={(e) => {
          setPreferredFacilityOne(e.target.value);
          }}
        onSelect={(val, name) => {
          setPreferredFacilityOne(val)
          }}
        inputContainerClasses='sign-up-form__input'
        showArrow={true}
        readOnly={false}
        options={facilitiesList.map(i => ({ label: i[1], value: i[0] }))}
        forceAutocomplete={true}
      />
{preferredFacilityTwo !== '' || showFacilityTwo ? <Input
        placeholder="Facility name"
        name='facility-two'
        label="Facility name"
        value={preferredFacilityTwo}
        onChange={(e) => {
          setPreferredFacilityTwo(e.target.value);
          }}
        onSelect={(val, name) => {
          setPreferredFacilityTwo(val)
          }}
        inputContainerClasses='sign-up-form__input'
        showArrow={true}
        readOnly={false}
        options={facilitiesList.map(i => ({ label: i[1], value: i[0] }))}
        forceAutocomplete={true}
      />
: null }

{preferredFacilityThree !== '' || showFacilityThree ?<Input
        placeholder="Facility name"
        name='facility-three'
        label="Facility name"
        value={preferredFacilityThree}
        onChange={(e) => {
          setPreferredFacilityThree(e.target.value);
          }}
        onSelect={(val, name) => {
          setPreferredFacilityThree(val)
          }}
        inputContainerClasses='sign-up-form__input'
        showArrow={true}
        readOnly={false}
        options={facilitiesList.map(i => ({ label: i[1], value: i[0] }))}
        forceAutocomplete={true}
      /> : null}

        
         
          <button type='button' disabled={addFacilitiesDisabled ? true : false} onClick={(e)=>handleAdd(e)}className={addFacilitiesDisabled ? 'onboarding__custom-button disabled' : 'onboarding__custom-button'}>
            <div className='flex'>
              <img src={plus}/>
              <p style={{marginBottom:0, marginLeft:'14px'}}>Add Facility</p>
            </div>
            </button>
        
          </form>
        <Button label="Next" active={true} type="submit" form="onboarding-form-3" class="long-btn filled"/>

        </div>
        </>
       
    )
}

export default StepThree