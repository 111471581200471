import React, {useState} from "react";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import './UnderstandingYourEstimate.css'

function UnderstandingYourEstimate({appointmentCenter, smallScreen, loaded, hasInsurance, quote, data, costAndLocationRef, facilityName, serviceName}) {
  const [paymentBreakdownToggle, setPaymentBreakdownToggle] = useState('View Payment Details');

    var PaymentBreakdown = () => {
        const insuranceCoverage = "- (" + quote.insurance_coverage + ")";
        return(
          <Accordion className='estimate__payment-breakdown'>
            <Card className='border-0'>
              <Card.Header className={(!smallScreen&&appointmentCenter) ? 'p-0' : 'bg-white p-0'}>
                <Accordion.Toggle 
                  as={Card.Text} 
                  eventKey='payment-breakdown' 
                  className='estimate__payment-accordion-toggle border-0 '
                  onClick={() => setPaymentBreakdownToggle(paymentBreakdownToggle == 'View Payment Details'? 'Hide Payment Details':'View Payment Details')}>
                  <span style={{cursor:'pointer'}}>{paymentBreakdownToggle}</span>
                  {paymentBreakdownToggle == 'View Payment Details' ? (
                    <i className="estimate__payment-accordion-toggle-open"></i>
                  ) : (
                    <i className="estimate__payment-accordion-toggle-close"></i>
                  )}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='payment-breakdown' id="payment-breakdown" className='border-0'>
                <Card.Body className='facility-estimate__payment-breakdown-details'>
                  <div className='facility-estimate__payment-breakdown-section'>
                    <div className='estimate__payment-breakdown-overline'> 
                      <p>Service Price</p>
                      <p>{quote.gross_price}</p>
                    </div>
                    <p className='estimate__payment-breakdown-description'>
                      {hasInsurance? "The price negotiated by your health insurer with the provider":"The cash price offered by the healthcare provider"}
                    </p>
                  </div>
                  <div className='facility-estimate__payment-breakdown-section'>
                    <div className='estimate__payment-breakdown-overline'> 
                      <p>Insurance Coverage</p>
                      <p>{insuranceCoverage}</p>
                    </div>
                    <p className='estimate__payment-breakdown-description'>How much your insurance will pay for this service based on your plan</p>
                  </div>
                  <div className='facility-estimate__payment-breakdown-section '>
                    <div className='estimate__payment-breakdown-overline'> 
                      <p>You Pay</p>
                      <p>{quote.user_cost}</p>
                    </div>
                    <p className='estimate__payment-breakdown-description'>Our estimate of how much you’ll owe for this service</p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )
      }
  
        if (loaded) {
          const youPayText = "You pay " + quote.user_cost;
          const youPayDescription = `This is only an estimate, but it is based on what ${facilityName ?? data?.facility} has reported for ${serviceName ?? data?.serviceName}. This price can vary based on your insurance and how you’ll pay.`
          
          return (
            <div ref={ costAndLocationRef ? (el)=> { costAndLocationRef.current = el} : null}  className={`${(!smallScreen&&appointmentCenter) ? '' : 'facility-estimate__estimate-section cost-and-coverage'}`} id='cost-and-location'>
              <div className='width-896'>
                <p  className='facility-estimate__estimate-section-title'>What you'll pay</p>
                <div className='facility-estimate__estimate-section-container'>
                  <p className='facility-estimate__estimate-section-emphasized width-368'>{youPayText}</p>
                  <div className={`${(!smallScreen&&appointmentCenter) ? '' : 'estimate__estimate-section-description width-480'}`}>
                    <p className='estimate__estimate-section-text'>{youPayDescription}</p>
                    {PaymentBreakdown()}
                  </div>
                </div>
              </div>
            </div>
          );
        } 
    
      
}

export default UnderstandingYourEstimate