import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import Input from "../../components/elements/Input";
import axios from "axios";

function StepTwo(props) {
  const employer = props.employer;
  const insurancePlan = props.insurancePlan;
  const planType = props.planType;
  const providersList = props.providersList;
  const setEmployer = props.setEmployer;
  const setInsurancePlan = props.setInsurancePlan;
  const setPlanType = props.setPlanType;

  const token = props.token;
  const userID = props.userID;

  const [insuranceIDNumber, setInsuranceIDNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");

  // validation
  const [insuranceIDNumberIsValid, setInsuranceIDNumberIsValid] =
    useState(null);
  const [groupNumberIsValid, setGroupNumberIsValid] = useState(null);

  const [stepTwoActiveBtn, setStepTwoActiveBtn] = useState(false);
  // active Next Button on Step 2 page
  const activateStepTwoButton = () => {
    if (
      employer !== "" &&
      insurancePlan !== "" &&
      planType !== "" &&
      insuranceIDNumber !== "" &&
      insuranceIDNumberIsValid != false &&
      groupNumber !== "" &&
      groupNumberIsValid != false
    ) {
      setStepTwoActiveBtn(true);
    }
  };

  useEffect(() => {
    setStepTwoActiveBtn(false);
    activateStepTwoButton();
  }, [employer, insurancePlan, planType, insuranceIDNumber, groupNumber]);

  // update user insurance
  function updateUserInsuranceInformation(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userID;
    axios
      .patch(
        URL,
        { user_information: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          props.switchPage("stepThree");
          props.setProgressPercentage(60);
        }
      })
      .catch(function (error) {
        if (error.response.data.errors === "Invalid insurance ID provided.") {
          setInsuranceIDNumberIsValid(false);
        } else if (
          error.response.data.errors === "Invalid group number provided."
        ) {
          setGroupNumberIsValid(false);
        }
      });
  }

  const planList = ["Self", "Family"];

  function formHandler(e) {
    e.preventDefault();
    const updateRequest = {
      issuer_legal_name: insurancePlan,
      member_id: insuranceIDNumber,
      group_id: groupNumber,
      plan_type: planType,
    };
    updateUserInsuranceInformation(updateRequest);
  }

  return (
    <>
      <p
        className="body-2 pointer"
        onClick={() => {
          props.switchPage("stepOne");
          props.setProgressPercentage(20);
        }}
      >
        <span className="back-arrow" /> STEP 1
      </p>
      <h2 className="onboarding__landing-header-secondary">
        Confirm your health insurance information.
      </h2>
      <div className="onboarding__textbox-primary">
        <p className="body-1">
          We’ll use your plan to learn your deductible and out-of-pocket
          maximum. Every field here is required to go to the next step.
        </p>
        <form
          className="onboarding__step1-form"
          id="onboarding-form-2"
          onSubmit={(e) => formHandler(e)}
        >
          <Input
            placeholder="Employer"
            name="employer"
            label="Employer"
            value={employer}
            onChange={(e) => setEmployer(e.target.value)}
            inputContainerClasses="sign-up-form__input"
            showArrow={false}
            readOnly={true}
            grayOut
          />
          <Input
            placeholder="Insurance plan"
            name="insurance-plan"
            label="Insurance plan"
            value={insurancePlan}
            onChange={(e) => {
              setInsurancePlan(e.target.value);
            }}
            onSelect={(val, name) => {
              setInsurancePlan(val);
            }}
            inputContainerClasses="sign-up-form__input"
            showDropdownArrow={true}
            readOnly={false}
            options={providersList.map((i) => ({ label: i, value: i }))}
            forceAutocomplete={true}
          />
          <Input
            selectOnly
            showAllOptions
            placeholder="Self or family plan"
            name="plan-type"
            label="Self or family plan"
            value={planType}
            onChange={(e) => {
              setPlanType(e.target.value);
            }}
            onSelect={(val, name) => {
              setPlanType(val);
            }}
            inputContainerClasses="sign-up-form__input"
            showDropdownArrow={true}
            readOnly={false}
            options={planList.map((i) => ({ label: i, value: i }))}
            forceAutocomplete={true}
          />
          <Input
            placeholder="Insurance ID number"
            name="insurance-ID-number"
            label="Insurance ID number"
            value={insuranceIDNumber}
            onChange={(e) => {
              setInsuranceIDNumber(e.target.value);
              setInsuranceIDNumberIsValid(null);
            }}
            inputContainerClasses="sign-up-form__input"
            showArrow={false}
            readOnly={false}
            errorMessage="The ID number provided doesn't match our records."
            showErrorMessage={
              insuranceIDNumber != "" && insuranceIDNumberIsValid == false
            }
            showErrorIcon={true}
          />

          <Input
            placeholder="Group number"
            name="group-number"
            label="Group number"
            value={groupNumber}
            onChange={(e) => {
              setGroupNumber(e.target.value);
              setGroupNumberIsValid(null);
            }}
            inputContainerClasses="sign-up-form__input"
            showArrow={false}
            readOnly={false}
            errorMessage="The group number provided doesn't match our records."
            showErrorMessage={groupNumber != "" && groupNumberIsValid == false}
            showErrorIcon={true}
          />
        </form>
        <Button
          label="Next"
          active={stepTwoActiveBtn}
          type="submit"
          form="onboarding-form-2"
          class="long-btn filled"
        />
      </div>
    </>
  );
}

export default StepTwo;
