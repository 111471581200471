import React from "react";
import Button from "../../elements/Button";
import healthTrophy from "../../../assets/healthTrophy.svg";
import { useHistory, useLocation } from "react-router-dom";

export default function InsuranceSearchCard({ smallScreen, state }) {
  const history = useHistory();
  return (
    <div className="flex-col-center insurance-search-card">
      <img src={healthTrophy} alt="Health trophy" />
      <div className="flex-col-center">
        <p className={"secondary-bold fw-700"}>Make the most of insurance</p>
        <p className={"text-description fw-400 center-text"}>
          Booking a covered appointment means the cost of the visit will count
          towards your deductible
        </p>
      </div>
      <Button
        onClick={() =>
          history.push({ pathname: "/cost-estimator", state: state })
        }
        label="Search covered services"
        active={true}
        class="outline-btn-white"
      />
    </div>
  );
}
