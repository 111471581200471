import React from "react";
import "./Home.css";
import ProfileCompletion from "../../../pages/Settings/ProfileCompletion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UserIcon from "../../elements/UserIcon";

export default function ProfileCompletionCard({
  token,
  smallScreen,
  fullName,
  profileCompletionPercentage,
  nextToComplete,
}) {
  const userID = useSelector((state) =>
    typeof state?.currentUser?.currentUser?.id === "string"
      ? state?.currentUser?.currentUser?.id
      : null
  );

  const accountListItems = [
    ["Insurance plan details", "/settings/plan-details"],
    ["Privacy and security", "/settings/privacy"],
    ["Notifications", "/settings/notifications"],
  ];

  const profileSections = [
    {
      title: "What's next",
      listItems: nextToComplete,
    },
    {
      title: "Account",
      listItems: accountListItems,
    },
  ];
  const ProfileSection = (section) => {
    return (
      <div
        key={section?.title}
        style={{ marginTop: 0 }}
        className="settings__whats-next"
      >
        <p
          className={
            smallScreen
              ? "margin-bottom-0"
              : "margin-bottom-0 heading-poppins fw-600"
          }
        >
          {section?.title}{" "}
        </p>

        <ul className="settings__bullet-list">
          {section?.listItems?.map((item) => (
            <Link
              to={
                section?.title === "Account"
                  ? { pathname: item[1], state: { userId: userID } }
                  : {
                      pathname: item.includes("notification")
                        ? "/settings/notifications"
                        : "/settings/my-profile",
                      state: { userId: userID },
                    }
              }
              key={item}
            >
              <li key={item} className={smallScreen ? "" : "text-description"}>
                {section?.title === "Account" ? item[0] : item + " >"}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      style={smallScreen === true ? { display: "none" } : {}}
      className="flex-col home-card"
    >
      <div
        className="flex-col"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <UserIcon style="large" fullName={fullName} />
        <p
          className={
            smallScreen ? "" : "heading-poppins fw-600 margin-bottom-0"
          }
        >
          {fullName}
        </p>
      </div>
      {profileCompletionPercentage === 100 ? null : (
        <div className="settings__profile-completion-section">
          <div
            style={{ marginBottom: "24px" }}
            className="settings__profile-completion"
          >
            <div className="settings__profile-completion-descripton">
              <p className={"home__profile-completion-text"}>
                Profile Completion
              </p>
              <p className={"home__profile-completion-percentage"}>
                {profileCompletionPercentage + "%"}
              </p>
            </div>

            <div className="settings__profile-completion-bar">
              {" "}
              <div
                className="settings__profile-completion-bar-filled"
                style={{ width: profileCompletionPercentage + "%" }}
              />{" "}
            </div>
          </div>
          <div className="profile-completion__settings-info">
            {profileSections?.map((section) => ProfileSection(section))}
          </div>
        </div>
      )}
    </div>
  );
}
