import './Glossary.css'
import React, { Component } from "react";
import axios from 'axios';
import Desktop from '../../components/templates/Desktop'
import Mobile from '../../components/templates/Mobile'
import Container from 'react-bootstrap/Container';
import Loading from '../../components/modules/Loading';
import TextInput from '../../components/elements/inputs/TextInput';

class Glossary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      glossary: [],
      allTerms: [],
      loading: true,
      searchTerm: '',
      smallScreen: ''
    }

    this.getGlossary = this.getGlossary.bind(this);
    this.filterAndSortGlossary = this.filterAndSortGlossary.bind(this);
    this.checkDevice = this.checkDevice.bind(this);
  }

  getGlossary() {
    const URL = process.env.REACT_APP_API_URL + 'glossaries';
    axios.get(URL, {headers: {'Authorization': `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`}})
    .then(res => {
      const glossary = res.data.data.map(g => g.attributes);
      this.setState({ glossary: glossary.sort((a, b) => a.glossary_term.localeCompare(b.glossary_term)) });
      this.setState({ allTerms: glossary.sort((a, b) => a.glossary_term.localeCompare(b.glossary_term)) });
      this.setState({ loading: false });

      if (this.props.location.state) {

        const passedTerm = this.props.location.state.searchTerm;
        if (passedTerm) {
          this.setState({
            searchTerm: passedTerm
          });
        
          this.filterAndSortGlossary(passedTerm);
        }
      }
    })
  }

  filterAndSortGlossary(searchString) {
    const levenSort = require('leven-sort');

    const filteredTerms = this.state.allTerms.filter(term => {
      return term.glossary_term.toUpperCase().includes(searchString.toUpperCase()) || term.glossary_definition.toUpperCase().includes(searchString.toUpperCase())});
    
    const searchTerm = searchString? searchString[0].toUpperCase() + searchString.substring(1).toLowerCase(): '';

    const sortedTerms = levenSort(filteredTerms, searchTerm, 'glossary_term' );

    this.setState({ 
      glossary: sortedTerms 
    }) 
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }) 

    this.filterAndSortGlossary(e.target.value);
  }

  checkDevice() {
    const resolution = window.innerWidth;
    const smallScreen = resolution <= 768;
    this.setState({
      smallScreen: smallScreen
    });
  }

  componentDidMount() {
    this.getGlossary();

    window.addEventListener("resize", this.checkDevice.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDevice.bind(this));
  }

  render() {
    const { loading } = this.state;
    if ( loading) {
      return (<Loading />);
    }

    const glossaryBody= (
      <div className='glossary'>
        <h1 className='bold glossary-heading large-heading'>Glossary</h1>
        <div className='glossary-body'>
          <div className='glossary-information'>
            <p className="text-large glossary-description">We’ve got your back! This glossary will help you understand confusing medical and health insurance terms.</p>
            <TextInput
              type='text'
              name='searchTerm'
              value={this.state.searchTerm}
              inputClasses= 'glossary-search-input'
              placeholder='Search the glossary'
              formGroupClasses= 'w-100'
              onChange={this.handleChange} 
              autoFocus={true}/>
          </div>
          <div className='glossary-terms'>
            {this.state.glossary.map(term => 
              <div className='glossary-term' key={term.glossary_term}>
                <p className='glossary-term__term'>{term.glossary_term}</p>
                <p className='glossary-term__def'>{term.glossary_definition}</p>
              </div>)} 
          </div>
        </div>
      </div>
    )


    if (window.innerWidth <= 768) {
      return (
        <Mobile 
        body={glossaryBody}
        showQuoteNavigation={false} 
        showMobileNav={true} 
        mobileNavColor='white'
      />

      );
    } else {
      return (
        <Desktop
          body={
            <Container>
              {glossaryBody}
            </Container>}
        />
      );
    }
  }
}

export default Glossary;
