import './QuestionSection.css';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from "react-bootstrap";
import { useState, useRef } from 'react';

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className='question-section__toggle border-0' type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

function QuestionSection(props){
  // PROPS
  // overline - Light text on top left of section in desktop view and top of section in mobile view
  // heading - Bold text on left hand side in desktop view and under the overline in mobile view
  // containerClass - classes to adjust styling of the question section container
  // containerRef - ref which is used for handling scrolling to different sections
  // questions - an array of questions and answers, if no questions are passed the QuestionAccordian will not display
  // details - any text that should go on the right hand side (in desktop view) of the section, will go above the QuestionAccordian if questions are passed
  // name - a text string to identify the element
  // activeKey - determines which accordian card should be open
  // setActiveKey - sets the active key

  var Question = (title, answer, index) => {
    let cardClass = props.activeKey === index ? "active border-0 question-section__question-card" : "border-0 question-section__question-card"
    return (
      <Card className={cardClass} key={index} >
        <CustomToggle
          as={Card.Header}
          eventKey={index}
          handleClick={() => {
            if (props.activeKey === index) {
              props.setActiveKey(null);
            } else {
              props.setActiveKey(index);
            }
          }}
        >
          {title}
          {props.activeKey === index ? " -" : " +"}
        </CustomToggle>
        <Accordion.Collapse eventKey={index} className='border-0'>
          <Card.Body className='question-section__question-card-body'>
            {answer}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  var QuestionAccordian = () => {
    var questionItems = props.questions.map((question, index) => Question(question.question, question.answer, props.name + '-' + index));
    return (
      <Accordion defaultActiveKey={0} activeKey={props.activeKey}>
        {questionItems}
      </Accordion>
    );
  }

  var className = 'question-section ' + props.containerClass;
  
  return (
    <div className={className} id={props.name} ref={props.containerRef} >
      <div className='question-section__width'>
        <div className='question-section__overline'>{props.overline}</div>

        <div style={props.servicePage ? {justifyContent:'space-between'}: null} className='question-section__content'>
          <div className={props.servicePage ? 'question-section__title width-368': 'question-section__title'}>{props.heading}</div>

          <div className={props.servicePage ? 'question-section__details width-480': 'question-section__details'}>
            {props.details}
            {props.questions ? QuestionAccordian() : null}
          </div>
        </div>
      </div>
    </div>
  );

}

export default QuestionSection;
