import React from "react";
import handlCare from "../../../assets/handlCare.svg";

export default function HandlInfoCard({ smallScreen }) {
  const informationContent = [
    {
      heading: "Say no to overpriced care",
      description:
        "Learn the cost of an appointment at different facilities before booking",
    },
    {
      heading: "Compare quality",
      description:
        "Access national quality and experience ratings from Medicare directly in the app",
    },
    {
      heading: "Manage appointments in one place",
      description:
        "Use Handl Concierge to book and manage appointments, no matter the facility",
    },
  ];
  return (
    <div className="handl-info">
      <div className="flex-col-center">
        <img
          className="margin-bottom-8"
          src={handlCare}
          alt="Handl care icon"
        />
        <p
          style={{ marginBottom: "24px" }}
          className={"fw-300 text-large color-070707"}
        >
          How Handl has you covered
        </p>
        <div className="handl-info-container">
          {informationContent.map((item, idx) => (
            <div key={idx} style={{ marginBottom: "24px" }}>
              <p
                className={
                  smallScreen
                    ? "fw-600 text-description dark-blue"
                    : "fw-600 text-subheading dark-blue"
                }
              >
                {item.heading}
              </p>
              <p
                className={
                  smallScreen ? "fw-400 body-3" : "fw-400 text-description"
                }
              >
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
