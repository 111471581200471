import "./Navigation.css";
import { React, useState, useEffect, memo } from "react";
import Navbar from "react-bootstrap/Navbar";
import searchIcon from "../../assets/icons/searchIcon.svg";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "../elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import navHomeLogo from "../../assets/navHomeLogo.svg";
import down from "../../assets/icons/down.svg";
import up from "../../assets/icons/up.svg";
import right from "../../assets/icons/right.svg";
import largeRight from "../../assets/icons/largeRight.svg";
import HHLogo from "../../assets/Handl Health - Emblem - 3D Blue.png";
import { useSelector } from "react-redux";
import UserIcon from "../elements/UserIcon";
import resourcesBlueDown from "../../assets/icons/resourcesBlueDown.svg";
import resourcesRight from "../../assets/icons/resourcesRight.svg";

function MainNavigation(props) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Navbar bg="white" sticky="top" expand="true" className="header">
      <Navbar.Brand href="/">
        <p className="text-large handl-logo">Handl Health</p>
      </Navbar.Brand>
      <Navbar.Text>
        <div className="icons">
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
          >
            <Dropdown.Toggle
              className="white nav-link"
              id="resources-dropdown-toggle"
              key="resources"
            >
              Resources
            </Dropdown.Toggle>

            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item href="/articles" eventKey="2" key="articles">
                Articles
              </Dropdown.Item>

              <Dropdown.Item href="/glossary" eventKey="1" key="glossary">
                Glossary
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Link
            className="nav-link"
            key="press"
            to={{ pathname: "/press_releases" }}
          >
            Press
          </Link>

          <Link
            className="nav-link"
            key="contact"
            to={{ pathname: "/contact" }}
          >
            Contact
          </Link>
        </div>
      </Navbar.Text>
    </Navbar>
  );
}

function MobileNavigation(props) {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const loggedIn = useSelector((state) => state?.currentUser?.loggedIn); //need to update this
  const history = useHistory();
  const location = useLocation();
  const navMenuOptions = [
    ...(loggedIn ? ["home"] : []),
    ...(loggedIn ? ["appointments"] : []),
    ["resources", ["articles", "glossary", "press"]],
  ];

  const helpSectionOptions = [
    ...(loggedIn
      ? [
          {
            id: 1,
            text: "Need help?",
            action: () => history.push("/settings/help-center"),
          },
          {
            id: 2,
            text: "Logout",
            action: () => props?.LogOut(),
          },
        ]
      : [
          {
            id: 3,
            text: "Create an account",
            action: () => history.push("/sign_up"),
          },
          {
            id: 4,
            text: "Need help?",
            action: () => history.push("/contact"),
          },
        ]),
  ];
  
  const navbarClasses = "px-3 w-100 mobile-header bg-light-blue";

  return (
    <>
      <Navbar
        style={{ paddingTop: "0.8125rem", paddingBottom: "0.8125rem" }}
        fixed="top"
        expand="true"
        className={navbarClasses}
      >
        <a
          onClick={() => setOpenNavigation(!openNavigation)}
          className="nav-toggle"
        >
          {Array.from(Array(3)).map((el, idx) => (
            <span
              key={idx}
              className={`bar ${openNavigation ? "x" : ""}`}
            ></span>
          ))}
        </a>
        {loggedIn === true && (
          <p
            style={{ marginBottom: "0" }}
            className="text-description fw-600 margin-bottom-0"
            onClick={() =>
              history.push({
                pathname: "/fancy_search",
                state: {
                  from: location.pathname,
                  userID: props.userID,
                  userZipcode: props.userZipcode,
                  searchActive: false,
                  smallScreen: true,
                },
              })
            }
          >
            Find an appointment {`>`}
          </p>
        )}
      </Navbar>
      <div
        className={`flex-col updated-mobile-nav-container ${
          openNavigation ? "updated-mobile-nav-container__active" : ""
        }`}
      >
        <div className="flex-col">
          <div
            style={{
              display: "flex",
              gap: "65px",
              alignItems: "center",
              paddingBottom: "36px",
            }}
          >
            {loggedIn === true ? (
              <>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "32px" }}
                >
                  <UserIcon
                    fullName={props?.userInformation?.fullName}
                    style="large"
                  />
                  <div
                    onClick={() => history.push("/settings/my-profile")}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <p
                      style={{ marginBottom: "3px" }}
                      className="text-small fw-400"
                    >
                      Policy Holder
                    </p>
                    <p
                      style={{ marginBottom: "8px" }}
                      className="text-medium fw-500"
                    >
                      {props?.userInformation?.policyHolder}
                    </p>
                    <p className="text-description fw-400">
                      Member ID: {props?.memberID}
                    </p>
                  </div>
                </div>

                <img src={right} alt="Right chevron icon" />
              </>
            ) : (
              <p
                style={{
                  fontSize: "32px",
                  paddingTop: "20px",
                  lineHeight: "48px",
                }}
                className="fw-700 default-blue"
              >
                Handl Health
              </p>
            )}
          </div>
          <div className="flex-col" style={{ gap: "32px" }}>
            {navMenuOptions.map((option) =>
              option?.[0] === "resources" ? (
                <div
                  key={option}
                  onClick={() => setShowResources(!showResources)}
                >
                  <div className="flex" style={{ gap: "13px" }}>
                    <p
                      style={{ marginBottom: "0" }}
                      className={`updated-mobile-nav-container__menu-options ${
                        showResources ? "default-blue" : ""
                      }`}
                    >
                      {option[0]}
                    </p>
                    <img
                      src={showResources ? resourcesBlueDown : resourcesRight}
                      style={{}}
                    />
                  </div>
                  <ul className={showResources ? "visible" : ""}>
                    {option[1].map((opt) => (
                      <Link
                        key={opt}
                        to={`${
                          opt === "press" ? "/press_releases" : `/${opt}`
                        }`}
                      >
                        <li
                          className="updated-mobile-nav__li-options"
                          key={opt}
                        >
                          {opt}
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              ) : (
                <p
                  onClick={() =>
                    history.push(option === "home" ? "/" : `/${option}`)
                  }
                  key={option}
                  className="updated-mobile-nav-container__menu-options"
                >
                  {option}
                </p>
              )
            )}
          </div>
          <div className="line" />
          <div
            className=" find-an-estimate-container flex"
            onClick={() =>
              history.push(
                loggedIn
                  ? {
                      pathname: "/fancy_search",
                      state: {
                        from: location.pathname,
                        userID: props.userID,
                        userZipcode: props.userZipcode,
                        searchActive: false,
                        smallScreen: true,
                      },
                    }
                  : { pathname: "/sign_in" }
              )
            }
          >
            <p
              style={{ marginBottom: "0" }}
              className="updated-mobile-nav-container__menu-options"
            >
              {loggedIn ? "Find an estimate" : "Sign in"}
            </p>
            <img src={largeRight} alt="Large right icon" />
          </div>
          <div className="line" />
          <div className="updated-mobile-nav-container__help-section">
            {helpSectionOptions.map((option) => (
              <p
                key={option.id}
                className="updated-mobile-nav__li-options"
                onClick={() => option.action()}
              >
                {option.text}
              </p>
            ))}
          </div>

          <img
            className="updated-mobile-nav-container__handl-logo"
            src={navHomeLogo}
            alt="Handl home logo"
          />
        </div>
      </div>
    </>
  );
}
function QuoteNavigation(props) {
  let classes = "back sticky-top " + props.classes;
  let textClasses = "small back-text " + props.textClasses;
  let arrowClasses = "back-arrow quote-navigation-arrow " + props.arrowClasses;

  return (
    <div className={classes} onClick={props.back}>
      <div className={arrowClasses}></div>
      <p className={textClasses}>{props.lastPage}</p>
    </div>
  );
}

function UserNavigation(props) {
  const loggedIn = useSelector((state) => state.currentUser.loggedIn); //need to update this
  const { token, removeToken } = useToken();
  const userID = useSelector((state) => state?.currentUser?.currentUser?.id);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUserInfoDropdown, setShowUserInfoDropdown] = useState(false);
  const [userInformation, setUserInformation] = useState({
    fullName: "",
    policyHolder: "",
  });
  const [memberID, setMemberID] = useState("");
  const [smallScreen, setSmallScreen] = useState(false);
  const [userZipcode, setUserZipcode] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getUserInformation();
    getUserInsuranceInformation();
  }, []);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  function goToSignUp() {
    window.location.href = "/sign_up";
  }

  function goToSignIn() {
    window.location.href = "/sign_in";
  }

  function LogOut() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "logout";
    axios
      .delete(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        window.location.href = "/sign_in";
        removeToken();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // get user info based on user id
  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const data = res.data.data.attributes;
        setUserZipcode(data.zip_code ? data.zip_code.zip_codes : "");
        setUserInformation({
          ...userInformation,
          policyHolder: data?.policy_holder ? data.policy_holder : "",
          fullName: data?.full_name ? data.full_name : "",
        });
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function getUserInsuranceInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInsuranceInformation = res.data.data.attributes;
        setMemberID(
          userInsuranceInformation?.member_id
            ? userInsuranceInformation.member_id
            : ""
        );
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const UserActions = () => {
    const settingsOptions = [
      ["Insurance plan details", "/settings/plan-details"],
      ["Privacy and security", "/settings/privacy"],
      ["Notifications", "/settings/notifications"],
    ];
    const UserInformation = () => {
      return (
        <div className="userActions-navigation__dropdown-user-information">
          <div>
            <p style={{ marginBottom: "3px" }} className="text-small fw-400">
              Policy Holder
            </p>
            <p style={{ marginBottom: "8px" }} className="text-medium fw-500">
              {userInformation?.policyHolder}
            </p>
            <p className="text-description fw-400">Member ID: {memberID}</p>
          </div>
          <div>
            <p className="userActions-navigation__dropdown-user-information-label">
              Account
            </p>
            <ul>
              {settingsOptions.map((opt) => (
                <Link key={opt[0]} to={opt[1]}>
                  <li>{opt[0]}</li>
                </Link>
              ))}
            </ul>
          </div>
          <Button
            label="View account"
            active={true}
            class="outline-btn-blue"
            onClick={() => history.push("/settings/my-profile")}
          />
          <Button
            label="Sign out"
            active={true}
            class="text-btn"
            onClick={(e) => LogOut()}
          />
        </div>
      );
    };
    if (loggedIn === true) {
      return (
        <div className="navigation-bar__user-actions">
          <div className="navigation-bar__user-actions-search">
            <div
              className={
                location.pathname.includes("/fancy_search")
                  ? "navigation-bar__user-actions-search-active"
                  : "navigation-bar__user-actions-search-inactive"
              }
            ></div>

            <img
              src={searchIcon}
              alt="Search"
              className="navigation-bar__user-actions-search-icon"
              onClick={() =>
                history.push({
                  pathname: "/cost-estimator",
                  state: {
                    from: location.pathname,
                    userID: userID,
                    userZipcode: userZipcode,
                    searchActive: false,
                    smallScreen: false,
                  },
                })
              }
            />
          </div>

          <Dropdown
            onToggle={(isOpen, event, metadata) => {
              setShowUserInfoDropdown(isOpen);
            }}
          >
            <Dropdown.Toggle
              className="navigation-bar__dropdown"
              variant="link"
              key="user-information"
            >
              <UserIcon
                style="small"
                active={showUserInfoDropdown}
                fullName={userInformation?.fullName}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="userActions-navigation__dropdown-user-information-menu"
              show={showUserInfoDropdown}
            >
              {UserInformation()}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="navigation-bar__user-actions">
          <Button
            label="Create an account"
            active={true}
            class="text-btn"
            className="navigation-bar__user-actions-btn"
            onClick={(e) => goToSignUp()}
          />

          <Button
            label="Sign in"
            active={true}
            class="outline-btn-blue"
            className="navigation-bar__user-actions-btn"
            onClick={(e) => goToSignIn()}
          />
        </div>
      );
    }
  };

  return !smallScreen ? (
    <div className="navigation-bar">
      <Link className="navigation-bar__link" key="press" to={{ pathname: "/" }}>
        <img src={HHLogo} alt="Handl Health logo" style={{ width: '60px', height: '60px' }} />
      </Link>
      <div className="navigation-bar__page-links">
        <Dropdown
          onToggle={(isOpen, event, metadata) => {
            setShowDropdown(isOpen);
          }}
        >
          <Dropdown.Toggle
            className="navigation-bar__dropdown"
            variant="link"
            key="resources"
          >
            <div className="userActions-navigation__dropdown-toggle flex">
              <p
                className={`userActions-navigation-bar__items ${
                  showDropdown ? "userActions-navigation__dropdown-open" : ""
                }`}
                style={{ marginBottom: "0" }}
              >
                Resources
              </p>
              <img
                style={{ marginLeft: "6px" }}
                src={showDropdown ? up : down}
                alt="down chevron"
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="userActions-navigation__dropdown-menu"
            show={showDropdown}
          >
            <Dropdown.Item href="/articles" eventKey="1" key="articles">
              Articles
            </Dropdown.Item>
            <Dropdown.Item href="/glossary" eventKey="2" key="glossary">
              Glossary
            </Dropdown.Item>
            <Dropdown.Item href="/press_releases" eventKey="3" key="press">
              Press
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Link
          className="navigation-bar__link userActions-navigation-bar__items"
          key="help"
          to={
            loggedIn
              ? { pathname: "/settings/help-center" }
              : { pathname: "/contact" }
          }
        >
          Help
        </Link>
      </div>
      {UserActions()}
    </div>
  ) : (
    <MobileNavigation
      userID={userID}
      userZipcode={userZipcode}
      userInformation={userInformation}
      memberID={memberID}
      LogOut={() => LogOut()}
    />
  );
}
const MemoizedUserNavigation = memo(UserNavigation, () => true);
export {
  MainNavigation,
  QuoteNavigation,
  MobileNavigation,
  UserNavigation,
  MemoizedUserNavigation,
};
