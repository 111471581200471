import SettingsSideBar from "./SettingsSideBar";
import Button from "../../components/elements/Button";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import Input from "../../components/elements/Input";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";
import ProfileCompletion from "./ProfileCompletion";

function MyProfile(props) {
  const { token } = useToken();
  const location = useLocation();
  const history = useHistory();
  const [userId, setUserId] = useState(
    location.state ? location.state.userId : null
  );
  const [facilitiesList, setFacilitiesList] = useState([
    { label: "", value: "" },
  ]);
  const [isPhoneValid, setIsPhoneValid] = useState(null);
  const [contentUpdated, setContentUpdated] = useState(false);
  const [userInformation, setUserInformation] = useState({
    name: "",
    email: "",
    employee_id: "10011000",
    birthdate: "01/20/1990",
    personal_email: "",
    pronouns: "",
    phone_number: "",
    zip_code: "",
    preferred_facility_1: "",
    preferred_id_1: "",
    preferred_facility_2: "",
    preferred_id_2: "",
    preferred_facility_3: "",
    preferred_id_3: "",

    next_to_complete: [],
  });

  const [inputStates, setInputStates] = useState({
    birthdate: true,
    personalEmail: true,
    phoneNumber: true,
    preferredFacility1: true,
    preferredFacility2: true,
    preferredFacility3: true,
  });

  const dobRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    getUserId();
    getFacilityList();
  }, []);

  useEffect(() => {
    getUserInformation();
  }, [userId]);

  useEffect(() => {
    getUserInformation();
  }, [contentUpdated]);

  function getUserId() {
    if (userId == null) {
      const URL = process.env.REACT_APP_DEVISE_API_URL + "users/users";
      axios
        .get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setUserId(res.data.data.id);
            getUserInformation();
          } else {
            history.push({
              pathname: "/sign_in",
            });
          }
        })
        .catch(function (error) {
          history.push({
            pathname: "/sign_in",
          });
        });
    } else {
      getUserInformation();
    }
  }

  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userId;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        var user_information = res.data.data.attributes;
        setUserInformation({
          ...userInformation,
          name: user_information.full_name ? user_information.full_name : "",
          email: user_information.email ? user_information.email : "",
          birthdate: user_information.formatted_birthdate
            ? user_information.formatted_birthdate
            : "",
          personal_email: user_information.secondary_email
            ? user_information.secondary_email
            : "",
          phone_number: user_information.phone_number
            ? user_information.phone_number
            : "",
          zip_code: user_information.preferred_zip_code
            ? user_information.preferred_zip_code
            : "",
          preferred_facility_1: user_information
            .preferred_facilities[0]?.[0]?.[0]
            ? user_information.preferred_facilities[0][0][0]
            : "",
          preferred_id_1: user_information.preferred_facilities[0]?.[0]?.[1]
            ? user_information.preferred_facilities[0][0][1]
            : "",
          preferred_facility_2: user_information
            .preferred_facilities[1]?.[0]?.[0]
            ? user_information.preferred_facilities[1][0][0]
            : "",
          preferred_id_2: user_information.preferred_facilities[1]?.[0]?.[1]
            ? user_information.preferred_facilities[1][0][1]
            : "",
          preferred_facility_3: user_information
            .preferred_facilities[2]?.[0]?.[0]
            ? user_information.preferred_facilities[2][0][0]
            : "",
          preferred_id_3: user_information.preferred_facilities[2]?.[0]?.[1]
            ? user_information.preferred_facilities[2][0][1]
            : "",
          pronouns: user_information.pronouns ? user_information.pronouns : "",
          next_to_complete: user_information.next_to_complete,
        });
        setContentUpdated(false);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function updateUserInformation(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userId;
    axios
      .patch(
        URL,
        { user_information: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
        } else {
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function updateUserZipCode() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/update_zip_code";
    axios
      .patch(
        URL,
        { zip_code: userInformation.zip_code },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
        } else {
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function updateUserFacility(userfacility, previousPreferredID) {
    if (userfacility === "") {
      const URL =
        process.env.REACT_APP_DEVISE_API_URL +
        "users/user_informations/update_preferred_facility";
      axios
        .patch(
          URL,
          { preferred_id: previousPreferredID },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setContentUpdated(true);
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    } else {
      var facilityId = facilitiesList.find((facility) =>
        facility.includes(userfacility)
      )[0];
      const URL =
        process.env.REACT_APP_DEVISE_API_URL +
        "users/user_informations/update_preferred_facility";
      axios
        .patch(
          URL,
          { facility_id: facilityId, preferred_id: previousPreferredID },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setContentUpdated(true);
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  }

  function getFacilityList() {
    const URL = process.env.REACT_APP_API_URL + "facilities";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setFacilitiesList(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function toggleReadOnly(input, value) {
    setInputStates({
      ...inputStates,
      [input]: !value,
    });
  }

  const BirthdateInput = () => {
    function validateDate(date) {
      // First check for the pattern
      if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return false;

      // Parse the date parts to integers
      var parts = date.split("/");
      var day = parseInt(parts[1], 10);
      var month = parseInt(parts[0], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    }

    function handleClick(e) {
      dobRef.current.focus();
      var isDateValid = validateDate(userInformation.birthdate);

      if (
        isDateValid == true ||
        inputStates.birthdate == true ||
        (inputStates.birthdate == false && userInformation.birthdate == "")
      ) {
        toggleReadOnly("birthdate", inputStates.birthdate);

        if (inputStates.birthdate == false) {
          const d = new Date(userInformation.birthdate);
          var updateRequest = { birthdate: d };

          updateUserInformation(updateRequest);
        }
      } else {
        console.log("error!");
      }
    }

    return (
      <Input
        innerref={dobRef}
        placeholder="Date of birth (MM/DD/YYYY)"
        name="birthdate"
        label="Date of birth (MM/DD/YYYY)"
        value={userInformation.birthdate}
        onChange={(e) => {
          setUserInformation({
            ...userInformation,
            birthdate: e.target.value,
          });
        }}
        inputContainerClasses="settings__input"
        showArrow={false}
        readOnly={inputStates.birthdate}
        showInputActions={true}
        iconOnClick={(e) => handleClick(e)}
      />
    );
  };

  const PersonalEmailInput = () => {
    function validateEmail(email) {
      const validEmail = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (email == "") {
        return true;
      } else {
        return validEmail != undefined;
      }
    }

    function handleClick() {
      emailRef.current.focus();
      var isEmailValid = validateEmail(userInformation.personal_email);

      if (
        isEmailValid == true ||
        inputStates.personalEmail == true ||
        (inputStates.personalEmail == false &&
          userInformation.personal_email == "")
      ) {
        toggleReadOnly("personalEmail", inputStates.personalEmail);

        if (inputStates.personalEmail == false) {
          var updateRequest = {
            secondary_email: userInformation.personal_email,
          };

          updateUserInformation(updateRequest);
        }
      } else {
        console.log("error!");
      }
    }

    return (
      <Input
        innerref={emailRef}
        placeholder="Personal email"
        name="personal-email"
        label="Personal email"
        value={userInformation.personal_email}
        onChange={(e) => {
          setUserInformation({
            ...userInformation,
            personal_email: e.target.value,
          });
        }}
        inputContainerClasses="settings__input"
        showArrow={false}
        readOnly={inputStates.personalEmail}
        showInputActions={true}
        iconOnClick={() => handleClick()}
      />
    );
  };

  const PhoneNumberInput = () => {
    function validatePhoneNumber(input) {
      const regex = new RegExp("^[\\d]{10}$");
      return regex.test(input);
    }

    function handleClick() {
      phoneRef.current.focus();
      var isPhoneValid = validatePhoneNumber(userInformation.phone_number);
      setIsPhoneValid(isPhoneValid);
      if (
        isPhoneValid == true ||
        inputStates.phoneNumber == true ||
        (inputStates.phoneNumber == false && userInformation.phone_number == "")
      ) {
        toggleReadOnly("phoneNumber", inputStates.phoneNumber);

        if (inputStates.phoneNumber == false) {
          var updateRequest = { phone_number: userInformation.phone_number };

          updateUserInformation(updateRequest);
        }
      } else {
        console.log("error!");
      }
    }

    return (
      <Input
        innerref={phoneRef}
        placeholder="Phone number"
        name="Phone-number"
        label="Phone number"
        value={userInformation.phone_number}
        onChange={(e) => {
          setIsPhoneValid(null);
          setUserInformation({
            ...userInformation,
            phone_number: e.target.value,
          });
        }}
        inputContainerClasses="settings__input"
        errorMessage="Please enter a valid phone number with 3-digit area code."
        showErrorMessage={
          userInformation.phone_number != "" && isPhoneValid == false
        }
        showArrow={false}
        readOnly={inputStates.phoneNumber}
        showInputActions={true}
        iconOnClick={() => handleClick()}
      />
    );
  };

  const ContactInformation = () => {
    return (
      <div className="settings__page-section">
        <p className="settings__subtitle">Contact Information</p>
        <div className="settings__noneditable-data">
          <p className="body-2">
            Name: <b>{userInformation.name}</b>
          </p>
          <p className="body-2">
            Work email: <b>{userInformation.email}</b>
          </p>
          <p className="body-2">
            Employee ID: <b>10011000</b>
          </p>
          {/* <p className='body-2'>DOB: <b>{userInformation.birthdate}</b></p> */}
        </div>
        {BirthdateInput()}
        {PersonalEmailInput()}
        {PhoneNumberInput()}
      </div>
    );
  };

  const Pronouns = () => {
    const pronounsList = ["He/Him", "She/Her", "They/Them"];
    return (
      <div className="settings__page-section">
        <p className="settings__subtitle">Pronouns</p>
        <p className="body-2">
          Let us know how you’d like to be addressed by Handl.
        </p>
        <Input
          selectOnly={true}
          showAllOptions
          placeholder="Pronouns"
          name="pronouns"
          value={userInformation.pronouns}
          onSelect={(val, name) => {
            setUserInformation({
              ...userInformation,
              pronouns: val,
            });

            const updateRequest = { pronouns: val };

            updateUserInformation(updateRequest);
          }}
          inputContainerClasses="sign-up-form__input"
          showDropdownArrow={true}
          readOnly={false}
          options={pronounsList.map((i) => ({ label: i, value: i }))}
          forceAutocomplete={true}
        />
      </div>
    );
  };

  const ServicePreferences = () => {
    let servicesPreferencesContent = CurrentServicePreferences();

    return (
      <div className="settings__page-section settings__service-preferences">
        <p className="settings__subtitle">Service Preferences</p>
        <p className="settings__description">
          Add up to 3 preferred facilities where you like to receive your
          healthcare.
        </p>
        {servicesPreferencesContent}
      </div>
    );
  };

  const CurrentServicePreferences = () => {
    var facilitySection = (
      <div className="settings__service-preference-section">
        <p>
          <b>My Preferred Facilities</b>
        </p>
      </div>
    );

    function handleClick(inputstate, preferredFacility, number, preferredID) {
      if (
        inputstate === true ||
        (inputstate === false && preferredFacility === "" && preferredID === "")
      ) {
        number === 1
          ? toggleReadOnly("preferredFacility1", inputstate)
          : number === 2
          ? toggleReadOnly("preferredFacility2", inputstate)
          : toggleReadOnly("preferredFacility3", inputstate);
      } else if (inputstate === false) {
        updateUserFacility(preferredFacility, preferredID);
        number === 1
          ? toggleReadOnly("preferredFacility1", inputstate)
          : number === 2
          ? toggleReadOnly("preferredFacility2", inputstate)
          : toggleReadOnly("preferredFacility3", inputstate);
      }
    }
    return (
      <div>
        {facilitySection}
        <Input
          style={{ paddingRight: "4rem" }}
          placeholder="Facility"
          name="preferred-facility"
          value={userInformation.preferred_facility_1}
          onChange={(e) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_1: e.target.value,
            });
          }}
          onSelect={(val, name) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_1: val,
            });
          }}
          inputContainerClasses="settings__input"
          showArrow={false}
          options={facilitiesList.map((i) => ({ label: i[1], value: i[0] }))}
          forceAutocomplete={true}
          readOnly={inputStates.preferredFacility1}
          showInputActions={true}
          iconOnClick={() =>
            handleClick(
              inputStates.preferredFacility1,
              userInformation.preferred_facility_1,
              1,
              userInformation.preferred_id_1
            )
          }
        />

        <Input
          style={{ paddingRight: "4rem" }}
          placeholder="Facility"
          name="preferred-facility"
          value={userInformation.preferred_facility_2}
          onChange={(e) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_2: e.target.value,
            });
          }}
          onSelect={(val, name) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_2: val,
            });
          }}
          inputContainerClasses="settings__input"
          showArrow={false}
          options={facilitiesList.map((i) => ({ label: i[1], value: i[0] }))}
          forceAutocomplete={true}
          readOnly={inputStates.preferredFacility2}
          showInputActions={true}
          iconOnClick={() =>
            handleClick(
              inputStates.preferredFacility2,
              userInformation.preferred_facility_2,
              2,
              userInformation.preferred_id_2
            )
          }
        />
        <Input
          style={{ paddingRight: "4rem" }}
          placeholder="Facility"
          name="preferred-facility"
          value={userInformation.preferred_facility_3}
          onChange={(e) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_3: e.target.value,
            });
          }}
          onSelect={(val, name) => {
            setUserInformation({
              ...userInformation,
              preferred_facility_3: val,
            });
          }}
          inputContainerClasses="settings__input read-write-input"
          showArrow={false}
          options={facilitiesList.map((i) => ({ label: i[1], value: i[0] }))}
          forceAutocomplete={true}
          readOnly={inputStates.preferredFacility3}
          showInputActions={true}
          iconOnClick={() =>
            handleClick(
              inputStates.preferredFacility3,
              userInformation.preferred_facility_3,
              3,
              userInformation.preferred_id_3
            )
          }
        />
      </div>
    );
  };

  const MyProfilePage = () => {
    return (
      <div className="settings__my-profile settings-page">
        <Link to={{ pathname: "/settings" }} className="settings__back-button">
          <p className="body-2 pointer">
            <span className="back-arrow" /> SETTINGS
          </p>
        </Link>
        <p className="settings__page-title">My Profile</p>
        {ProfileCompletionSection()}
        {WhatsNextSection()}
        {ContactInformation()}
        {Pronouns()}
        {ServicePreferences()}
      </div>
    );
  };

  const itemToComplete = (item) => {
    return (
      <li key={item}>
        <p className="body-2">{item}</p>
      </li>
    );
  };

  const WhatsNextSection = () => {
    if (userInformation.next_to_complete.length >= 1) {
      var listItems = userInformation.next_to_complete.map((item) =>
        itemToComplete(item)
      );

      return (
        <div className="settings__whats-next mobile-hide">
          <p className="settings__subtitle">What's next: </p>
          <ul className="settings__bullet-list">{listItems}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const ProfileCompletionSection = () => {
    return (
      <div className="mobile-only">
        <ProfileCompletion token={token} />
      </div>
    );
  };

  return (
    <SidebarLayout
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses="settings__side-bar"
      content={MyProfilePage()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default MyProfile;
