import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";

export function extractFeeText(charge) {
  if (charge === null || charge.total_cost === null) return "Rate not published";
  return (
    charge?.total_cost?.human_readable ||
    charge?.total_cost?.percentage + "% of billed charges"
  );
}

export function renderEstimatedCost(costType, estimate) {
  // Define the labels for each cost type
  const labels = {
    insurance_coverage: "Est. Insurance",
    out_of_pocket_cost: "Est. Out of Pocket"
  };

  // Access the cost information based on the costType argument
  const costInfo = estimate
    ?.estimated_cost
    ?.[costType]
    ?.human_readable;

  // Conditionally render the label and cost information
  if (costInfo) {
    return (
      <>
        <b>{labels[costType]}: </b>
        {costInfo}
        <br/>
      </>
    );
  }
  return null; // Return null if there's no cost information to render
}

export async function handleAPIFetch(relativeURL, apiKey) {
  const baseURL = process.env.REACT_APP_CE_URL;
  try {
    const response = await fetch(baseURL + relativeURL, {
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export default function useFuzzySearchQueryHandler(
  apiEndpoint,
  stateUpdate,
  dataType,
  apiKey
) {
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length >= 3) {
      handleAPIFetch(`${apiEndpoint}?query=${query}`, apiKey)
        .then((data) => {
          const newDataHash = {};
          data[dataType].forEach((item) => {
            newDataHash[item.display] =
              item[dataType === "services" ? "billing_code" : "taxonomy_code"];
          });
          stateUpdate(newDataHash);
        })
        .catch((error) => console.error(`Error fetching ${dataType}:`, error));
    }
  }, [query, apiEndpoint, dataType, apiKey, stateUpdate]);

  return (event) => {
    setQuery(event.target.value);
  };
}
