export function toTitleCase(inputString) {
  if (!inputString) return;
  return (
    inputString
      //splits string into array of words, separating string into individual spaces
      .split(" ")
      //takes the first letter and makes upper case or lowercase
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      //mods back into a string
      .join(" ")
  );
}
