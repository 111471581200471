import React from "react";
import "./UserIcon.css";

export default function UserIcon({ fullName, style, active }) {
  const userInitials =
    fullName?.split(" ")[0].charAt(0) +
    fullName?.split(" ")[fullName?.split(" ").length - 1].charAt(0);
  return (
    <div className={`user-profile-icon ${style}`}>
      <div className={active === true ? "active-open" : "hidden-selected"} />
      <p style={{ marginBottom: "0" }} className="text-large fw-600">
        {userInitials}
      </p>
    </div>
  );
}
