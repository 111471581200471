import React, {useState, useEffect} from 'react'
import Button from '../../components/elements/Button';
import Input from '../../components/elements/Input';
import axios from 'axios';

function StepOne(props) {

  const dateOfBirth = props.dateOfBirth
  const firstName = props.firstName
  const preferredName = props.preferredName
  const pronouns = props.pronouns
  const personalEmail = props.personalEmail
  const phoneNumber = props.phoneNumber
  const sex = props.sex

  const setDateOfBirth = props.setDateOfBirth
  const setPreferredName = props.setPreferredName
  const setPronouns = props.setPronouns
  const setPersonalEmail = props.setPersonalEmail
  const setPhoneNumber = props.setPhoneNumber
  const setSex = props.setSex

  const token = props.token
  const userID = props.userID


  // validation
  const [dobIsValid, setDobIsValid] = useState(null)
  const [emailIsValid, setEmailIsValid] = useState(null)
  const [phoneIsValid, setPhoneIsValid] = useState(null)

  const [activeBtn, setActiveBtn] = useState(false)

// active Next Button on Step 1 page
    const activateButton = () => {
    if (dobIsValid !=false && pronouns !=='' && sex !== ''){
        setActiveBtn(true)
        }
      }
      useEffect(() => {
        setActiveBtn(false)
        activateButton()
      }, [pronouns, dobIsValid, sex]);
  
    
    // update user information
    function updateUserInformation(data) {
        const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/' + userID;
        axios.patch(URL, { user_information: data }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(res => { 
              if (res.status == 200) {
              props.switchPage('stepTwo')
              props.setProgressPercentage(40)
              } 
            }).catch(function(error) {
              console.log(error.response);
            });
      }

    const pronounsList = ["He/Him", "She/Her", "They/Them"]
    const sexList = ["Male", "Female"]
    
      function validateDate(date) {
        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date))
          return false;
  
        // Parse the date parts to integers
        var parts = date.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
  
        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;
  
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
  
        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;
  
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
      }
    

    function validateEmail() {
      const validEmail = String(personalEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  
      if (personalEmail == '') {
        return null;
      } else {
        return validEmail != undefined;
      }
    }
    
    function validatePhoneNumber(input) {
      if (input !==''){
        const regex = new RegExp("^[\\d]{10}$")
      return regex.test(input)
      }
      
    }

    function formHandler(e) {
      e.preventDefault()
      const updateRequest = ({ birthdate: dateOfBirth, secondary_email: personalEmail, phone_number: phoneNumber, preferred_name: preferredName, pronouns: pronouns, sex: sex });
      if (dobIsValid !=false && phoneIsValid != false && emailIsValid != false){
      updateUserInformation(updateRequest)

      }
    }
    return (
        <>
         <p className='body-2 pointer' onClick={()=>{props.switchPage('letsGo'); props.setProgressPercentage(0)}}><span className='back-arrow' /> BACK</p>
        <h2 className='onboarding__landing-header-secondary' >Welcome {firstName}! <br/> We need a few quick deets.</h2>
        <div className='onboarding__textbox-primary'>
        <p style={{maxWidth:'670px'}} className='body-1'> This information will help us communicate with you in the right way.</p>

        <form className='onboarding__step1-form' id="onboarding-form" onSubmit={(e)=>formHandler(e)} noValidate>

        <Input
        placeholder="Date of birth (MM/DD/YYYY)"
        name="date-of-birth"
        label="Date of birth"
        value={dateOfBirth}
        onChange={(e) => {setDateOfBirth(e.target.value); setDobIsValid(null)}}
        onBlur={(e) => {setDobIsValid(validateDate(dateOfBirth))}}
        inputContainerClasses='sign-up-form__input'
        showArrow={false}
        readOnly={false}
        errorMessage="Please enter a valid date of birth."
        showErrorMessage={dateOfBirth != '' && dobIsValid == false}
        showErrorIcon={true}
      />
             <Input
        placeholder="Preferred name"
        name="preferred-name"
        label="Preferred name"
        value={preferredName}
        onChange={(e) => setPreferredName(e.target.value)}
        inputContainerClasses='sign-up-form__input'
        showArrow={false}
        readOnly={false}
      />

    <Input
        selectOnly={true}
        showAllOptions
        placeholder="Pronouns"
        name="pronouns"
        label="Pronouns"
        value={pronouns}
        onSelect={(val, name) => {
          setPronouns(val)
          }}
        inputContainerClasses='sign-up-form__input'
        showDropdownArrow={true}
        readOnly={false}
        options={pronounsList.map(i => ({ label: i, value: i }))}
        forceAutocomplete={true}
      />

    <Input
        selectOnly={true}
        showAllOptions
        placeholder="Sex"
        name="sex"
        label="Sex"
        value={sex}
        onSelect={(val, name) => {
          setSex(val)
          }}
        inputContainerClasses='sign-up-form__input'
        showDropdownArrow={true}
        readOnly={false}
        options={sexList.map(i => ({ label: i, value: i }))}
        forceAutocomplete={true}
      />
               <Input
        placeholder="Personal email"
        name="personal-email"
        label="Personal email"
        value={personalEmail}
        onChange={(e) => {setPersonalEmail(e.target.value); setEmailIsValid(null)}}
        onBlur={(e) => setEmailIsValid(validateEmail())}
        inputContainerClasses='sign-up-form__input'
        showArrow={false}
        readOnly={false}
        errorMessage="Please enter a valid email address."
        showErrorMessage={personalEmail != '' && emailIsValid == false}
        showErrorIcon={true}
      />
                 <Input
        placeholder="Phone number"
        name="phone-number"
        label="Phone number"
        value={phoneNumber}
        onChange={(e) => {setPhoneNumber(e.target.value); setPhoneIsValid(null)}}
        onBlur={(e) => setPhoneIsValid(validatePhoneNumber(e.target.value))}
        inputContainerClasses='sign-up-form__input'
        showArrow={false}
        readOnly={false}
        errorMessage="Please enter a valid phone number."
        showErrorMessage={phoneNumber != '' && phoneIsValid == false}
        showErrorIcon={true}
      />
      

        </form>
        <Button label="Next" active={activeBtn} type="submit" form="onboarding-form" class="long-btn filled"/>

        </div>
        </>
       
    )
}

export default StepOne