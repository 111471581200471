import {
  Link
} from 'react-router-dom';
import './ArticleCard.css';

function ArticleCard(props) {
  const article = props.article;

  let dateAndKeywords = (<span>{article.date + ' • '}<span className='article-card__article-keywords'>{article.article_keywords.join(', ')}</span></span>);

    return(
      <Link className='article-card__link' to={{pathname: `/articles/${article.url}`}} key={article.url} >
        <li className='article-card__body' key={article.title}>
          <div className='article-card__article-information'>
            <div className='article-card__article-title'>{article.title}</div>
            <div className='article-card__article-date-and-keywords'>{dateAndKeywords}</div>
            <div className='article-card__article-author'>
              <img className='article-card__author-icon' src={article.author_image} />
              <div className='article-card__author-name'>{article.author_name}</div>
            </div>
          </div>

          <div className='article-card__article-image-container'>
            <img className='article-card__article-image' src={article.header_image} />
          </div>
        </li>
      </Link>
    );
}

export default ArticleCard
