import React, { Fragment, useState, useEffect } from "react";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import SettingsSideBar from "./SettingsSideBar";
import useToken from "../../hooks/useToken";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap";
import down from "../../assets/icons/down.svg";
import up from "../../assets/icons/up.svg";

function HelpCenter(props) {
  const { token } = useToken();
  const [activeKey, setActiveKey] = useState(0);

  const [showAllFAQs, setShowAllFAQs] = useState(false);

  useEffect(() => {
    if (!showAllFAQs) {
      window.scrollTo(0, 0);
    }
  }, [showAllFAQs]);

  function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      handleClick();
    });

    return (
      <div
        className="settings__accordian-toggle border-0"
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }

  const HelpCard = (title, body, index, idx) => {
    let cardClass =
      activeKey === index
        ? "active help-center-card-title"
        : "help-center-card-title";
    return (
      <Fragment key={idx}>
        {idx < 4 && !showAllFAQs ? (
          <div>
            {idx === 0 && <p className="help-subtitle">Common FAQs</p>}

            <Card className={cardClass} key={index}>
              <CustomToggle
                as={Card.Header}
                eventKey={index}
                handleClick={() => {
                  if (activeKey === index) {
                    setActiveKey(null);
                  } else {
                    setActiveKey(index);
                  }
                }}
              >
                <span className="help-accordian-span-title">{title}</span>
                <span className="help-accordian-span-icon">
                  {activeKey === index ? " -" : " +"}
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey={index} className="border-0">
                <Card.Body className="settings__privacy-card-body">
                  {body}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        ) : (
          showAllFAQs && (
            <div>
              {idx === 0 ? (
                <p className="help-subtitle">Common FAQs</p>
              ) : idx === 4 ? (
                <p className="help-subtitle">
                  Services, Billings & Appointments
                </p>
              ) : idx === 15 ? (
                <p className="help-subtitle">Account</p>
              ) : idx === 19 ? (
                <p className="help-subtitle">Insurance</p>
              ) : null}
              <Card className={cardClass} key={index}>
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  <span className="help-accordian-span-title">{title}</span>
                  <span className="help-accordian-span-icon">
                    {activeKey === index ? " -" : " +"}
                  </span>
                </CustomToggle>
                <Accordion.Collapse eventKey={index} className="border-0">
                  <Card.Body className="settings__privacy-card-body">
                    {body}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          )
        )}
      </Fragment>
    );
  };

  const HelpCenterPage = () => {
    const helpInformation = [
      {
        title: "What should I do in an emergency?",
        body: "What should I do in an emergency?",
      },
      {
        title: "What do I use Handl Health for?",
        body: "What do I use Handl Health for?",
      },
      {
        title: "How do I contact the Concierge?",
        body: "How do I contact the Concierge?",
      },
      {
        title: "How does Handl Health protect my data?",
        body: "How does Handl Health protect my data?",
      },
      {
        title: "I can’t find the service I need, what should I do?",
        body: "I can’t find the service I need, what should I do?",
      },
      {
        title:
          "My doctor gave me a referral, how do I use Handl Health to schedule the appointment?",
        body: "My doctor gave me a referral, how do I use Handl Health to schedule the appointment?",
      },
      {
        title: "Can I search a doctor by name",
        body: "Can I search a doctor by name",
      },
      {
        title: "How do I know if a provider is in-network?",
        body: "How do I know if a provider is in-network?",
      },
      {
        title: "Do all of my in-network providers feature on Handl Health?",
        body: "Do all of my in-network providers feature on Handl Health?",
      },
      {
        title: "What happens if I go to a provider that is out-of-network?",
        body: "What happens if I go to a provider that is out-of-network?",
      },
      {
        title: "How do I make an appointment?",
        body: "How do I make an appointment?",
      },
      {
        title: "How do I cancel an appointment?",
        body: "How do I cancel an appointment?",
      },
      {
        title: "I canceled my appointment, what should I do next?",
        body: "I canceled my appointment, what should I do next?",
      },
      {
        title: "I got a bill from my provider, what should I do with it?",
        body: "I got a bill from my provider, what should I do with it?",
      },
      {
        title:
          "When I choose a facility for an appointment, how do I know which doctor will treat me?",
        body: "When I choose a facility for an appointment, how do I know which doctor will treat me?",
      },
      {
        title: "What should I do in an emergency?",
        body: "What should I do in an emergency?",
      },
      {
        title: "What do I use Handl Health for?",
        body: "What do I use Handl Health for?",
      },
      {
        title: "How do I contact the Concierge?",
        body: "How do I contact the Concierge?",
      },
      {
        title: "How does Handl Health protect my data?",
        body: "How does Handl Health protect my data?",
      },
      {
        title: "How does Handl Health work with my insurer?",
        body: "How does Handl Health work with my insurer?",
      },
      {
        title: "I changed my insurance plan, what happens next?",
        body: "I changed my insurance plan, what happens next?",
      },
      {
        title: "How do I contact the Concierge?",
        body: "How do I contact the Concierge?",
      },
      {
        title: "What happens to my Handl Health account if I change employers?",
        body: "What happens to my Handl Health account if I change employers?",
      },
      {
        title:
          "What’s the difference between Handl Health and my insurance portal?",
        body: "What’s the difference between Handl Health and my insurance portal?",
      },
      {
        title: "How much does it cost to use Handl Health?",
        body: "How much does it cost to use Handl Health?",
      },
    ];

    let helpInformationCards = helpInformation.map((item, index) =>
      HelpCard(item.title, item.body, "privacy-" + index, index)
    );

    const contactInfo = [
      {
        id: 1,
        title: "Contact Handl Health",
        emailSubfield: "via email",
        emailSubfieldInfo: "support@handlhealth.com",
        subfield2: "via text support",
        subifield2Info: "243-030-1122",
      },
      {
        id: 2,
        title: "Contact your insurance",
        emailSubfield: "via email",
        emailSubfieldInfo: "support@aetna.com",
        subfield2: "via phone",
        subifield2Info: "243-030-1122",
        subfield3: "via website",
        subfield3Info: "www.aetna.com",
      },
      {
        id: 3,
        title: "Contact your employer",
        emailSubfield: "via email",
        emailSubfieldInfo: "support@handlhealth.com",
        subfield2: "via website",
        subifield2Info: "www.warnerbros.com",
      },
    ];

    return (
      <div className="settings__privacy-and-security settings-page">
        <Link to={{ pathname: "/settings" }} className="settings__back-button">
          <p className="body-2 pointer">
            <span className="back-arrow" /> SETTINGS
          </p>
        </Link>
        <p className="settings__page-title">Help Center</p>
        <div className={showAllFAQs ? "default show" : "default"}>
          <div className="settings__privacy-information">
            <Accordion defaultActiveKey={0} activeKey={activeKey}>
              {helpInformationCards}
            </Accordion>
          </div>
        </div>

        <div className="help-center-view-all-container">
          <p
            className="help-center-view-all"
            onClick={() => setShowAllFAQs(!showAllFAQs)}
          >
            {showAllFAQs ? "Collapse" : "View all FAQs"}
          </p>
          <img src={showAllFAQs ? up : down} />
        </div>
        {contactInfo.map((item) => (
          <div className="help-contact-card" key={item.id}>
            <p className="plan-details-main">{item.title}</p>
            <p className="plan-details-items">
              {item.emailSubfield}: <b>{item.emailSubfieldInfo}</b>
            </p>
            <p className="plan-details-items">
              {item.subfield2}: <b>{item.subifield2Info}</b>
            </p>
            {item.subfield3 && (
              <p className="plan-details-items">
                {item.subfield3}: <b>{item.subfield3Info}</b>
              </p>
            )}
          </div>
        ))}
      </div>
    );
  };
  return (
    <SidebarLayout
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses="settings__side-bar"
      content={HelpCenterPage()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default HelpCenter;
