import React, {useState} from 'react'
import QuestionSection from "../../components/sections/QuestionSection";
import './ServiceDetailSection.css'

function ServiceDetailSection({appointmentCenter, smallScreen, service,serviceDetailsRef}) {
  const [activeKey, setActiveKey] = useState(0);
    var Why = () => {
        return ( 
       
        <QuestionSection
        // servicePage
        containerClass={(!smallScreen&&appointmentCenter===true) ? 'appointment-detail__desktop-section' : 'facility-estimate__estimate-section'}
        name='why-section'
        overline='Why you need this'
        questions={service?.why.items}
        details={<div className= {smallScreen ? 'body-2' : 'body-1'} style={{marginBottom:'24px'}}>{service.why.description}</div>}
        activeKey={activeKey}
        setActiveKey={(val) => setActiveKey(val)}
        />
     
        
          
        );
      }
    
      var How = () => {
        return (
    
         
          <QuestionSection
          servicePage
          containerClass={(!smallScreen&&appointmentCenter===true) ? 'appointment-detail__desktop-section' : 'facility-estimate__estimate-section'}
          name='how-section'
          overline='How to prepare'
          questions={service.how.items}
          details={<div className= {smallScreen ? 'body-2' : 'body-1'} style={{marginBottom:'24px'}}>{service.how.description}</div>}
          activeKey={activeKey}
          setActiveKey={(val) => setActiveKey(val)}
          
        />
     
          
        );
      }
    
      var What = () => {
        return (
    
        
          <QuestionSection
          servicePage
          style={{paddingBottom:'40px'}}
          containerClass={(!smallScreen&&appointmentCenter===true) ? 'appointment-detail__desktop-section' : 'facility-estimate__estimate-section'}
          name='what-section'
          overline='What to expect'
          questions={service.what.items}
          details={<div className= {smallScreen ? 'body-2' : 'body-1'}style={{marginBottom:'24px'}}>{service.what.description}</div>}
          activeKey={activeKey}
          setActiveKey={(val) => setActiveKey(val)}
        />
          
          
        );
      }
        return (
          <div ref={serviceDetailsRef ? (el)=> {serviceDetailsRef.current = el}: null}  id="service-details"> 
            <div className={(!smallScreen&&appointmentCenter===true) ? 'width-820 appointment-detail__desktop-card' : ''}>
              {Why()} 
            </div>
            <div className={(!smallScreen&&appointmentCenter===true) ? 'width-820 appointment-detail__desktop-card' : ''}>
              {How()}
            </div>
            <div className={(!smallScreen&&appointmentCenter===true) ? 'width-820 appointment-detail__desktop-card' : ''}>
              {What()} 
            </div>
          </div>
          
        )
    
}

export default ServiceDetailSection