import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "nuka-carousel";
import leftBlack from "../../../assets/icons/leftBlack.svg";
import right from "../../../assets/icons/right.svg";
import { Link, useHistory } from "react-router-dom";
import Button from "../../elements/Button";

export default function ArticleCard({ smallScreen, articles }) {
  const history = useHistory();

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  return (
    <div className="article-card">
      <div className="article-card__text-container">
        {!smallScreen ? (
          <h5>Recommended reading</h5>
        ) : (
          <p className="subheading-crimson-pro margin-bottom-8">
            Recommended reading
          </p>
        )}
        <p className={"text-medium fw-400"}>
          Stay in the loop with what’s happening in healthcare
        </p>
      </div>
      <Carousel
        swiping={true}
        scrollMode="remainder"
        slidesToShow={1}
        cellAlign="left"
        cellSpacing="16"
        renderTopLeftControls={false}
        renderTopRightControls={false}
        renderCenterLeftControls={false}
        renderCenterRightControls={false}
        renderBottomCenterControls={({
          slideCount,
          currentSlide,
          previousSlide,
          nextSlide,
          goToSlide,
        }) => (
          <div className="slider-control-bottomcenter">
            <ul>
              <img
                style={{ marginRight: "16px", cursor: "pointer" }}
                src={leftBlack}
                onClick={previousSlide}
              ></img>

              {[...Array(slideCount)].map((e, key) => (
                <li
                  onClick={() => goToSlide(key)}
                  className={currentSlide == key ? "active" : undefined}
                  key={key}
                ></li>
              ))}
              <img
                style={{ marginLeft: "16px", cursor: "pointer" }}
                src={right}
                onClick={nextSlide}
              ></img>
            </ul>
          </div>
        )}
      >
        {shuffle(articles)
          ?.slice(-3)
          .map((article) => (
            <div key={article?.id} className="article-card-container">
              <Link to={`/articles/${article?.attributes?.url}`}>
                <div>
                  <img
                    loading="lazy"
                    className="article-card-container__img"
                    src={
                      article?.attributes?.mini_header_image ??
                      article?.attributes.header_image
                    }
                    alt="Article header image"
                  />
                  <div>
                    <p
                      style={{ marginBottom: "12px" }}
                      className={"heading-poppins fw-600"}
                    >
                      {article?.attributes.title}
                    </p>
                    <p style={{ marginBottom: "8px" }} className={"body-3"}>
                      {article?.attributes.date +
                        " · " +
                        article?.attributes.article_keywords
                          .slice(0, 2)
                          .join(", ")}
                    </p>{" "}
                    <div className="article-card-container_author-section">
                      <img
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        src={article?.attributes.author_image}
                        loading="lazy"
                        alt="Author image"
                      />
                      <p
                        className={"body-3 margin-bottom-0"}
                        style={{ letterSpacing: "0.6px" }}
                      >
                        {article?.attributes.author_name}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </Carousel>
      {smallScreen && (
        <Button
          label="See all articles"
          class="text-btn"
          className="article-card__see-all-btn"
          active="active"
          onClick={() => history.push("/articles")}
        />
      )}
    </div>
  );
}
