import "./SidebarLayout.css";
import Footer from "../sections/Footer";
import {
  MobileNavigation,
  MemoizedUserNavigation,
} from "../../components/sections//Navigation";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

function SidebarLayout(props) {
  const [smallScreen, setSmallScreen] = useState(false);
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const sidebarClasses = "sidebar-layout__sidebar " + props.sidebarClasses;
  const contentClasses = "sidebar-layout__content " + props.contentClasses;

  return (
    <div className="sidebar-layout">
      {<MemoizedUserNavigation />}
      {props.progressBar === true ? (
        <ProgressBar className="handl-progress-bar" now={props.progressNow} />
      ) : null}
      <div
        className={
          props.noExtraHeight
            ? null
            : props.signIn
            ? "sidebar-layout__body-sign-in"
            : "sidebar-layout__body-default"
        }
      >
        {props.sidebarClasses ? (
          <div className={sidebarClasses}>{props.sidebar}</div>
        ) : null}

        <div className="sidebar-layout__content-container">
          <div
            className={
              props.contentClasses || smallScreen ? contentClasses : null
            }
          >
            {props.content}
          </div>
        </div>
      </div>
      {props.customFooter}
      {props.showFooter == true ? <Footer /> : null}
    </div>
  );
}

export default SidebarLayout;
