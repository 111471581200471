import axios from 'axios';
import { useState, useEffect, createRef } from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import Loading from '../../components/modules/Loading';
import ServiceItem from '../../components/modules/ServiceItem';
import search from '../../assets/icons/search.png';
import './Search.css';


function Search(props) {
  const [services, setServices] = useState([]);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [mostSearchedServices, setMostSearchedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMostSearched, setShowMostSearched] = useState(true);
  const [service, setService] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [suggestions, setSuggestions] = useState('');

  const inputRef = createRef();

  const history = useHistory();
  
  useEffect(() => {
    async function fetchServices() {
      const url = process.env.REACT_APP_API_URL + 'services/searchable_services';
      const request = await axios.get(url);
      const services = request.data.data.map(s => s.attributes);

      setServices(services);
      setServiceGroups([...new Set(services.map(service => service.service_group_name ))].sort((a, b) => a.localeCompare(b)).slice(0,10));
      setMostSearchedServices(services.sort((a, b) => b.searches - a.searches).slice(0,3));
      setLoading(false);
    }
    fetchServices();
  }, [props]);

  function searchService(e) {
    e.stopPropagation();
    e.preventDefault();
    createUserServiceQuery();

    const URL = process.env.REACT_APP_API_URL + 'services/searchable_service_search';
    axios.get(URL, {
      params: {
          search: service
        }
      })
      .then(res => {let serviceData = res.data;
                    if (serviceData == 'Service Not Found') {
                      setErrorMessage(true);
                      console.log(errorMessage);
                    } else {
                      goToServicePage(serviceData);
                    }});
  }

  function goToServicePage(s) {
    history.push({
      pathname: '/service_search',
      state: { service: s.cpt_code }
    })
  }

  function goToServiceList() {
    if (props.smallScreen) {
      props.onClose();
    } 
    document.getElementById('service-offerings').scrollIntoView();
  }

  function handleChange(value) {
    const menuItems = [...inputRef.current.refs.menu.children].map(item => item.children);
    const visibleServices = menuItems.map(item => item[0].id);
    
    setSuggestions(visibleServices);
    setService(value);
    setUserInput(value);
  }

  function handleSelect(value) {
    setService(value);
  }

  function createUserServiceQuery()  {
    if (userInput != '') {
      console.log('creating service query');
      const URL = process.env.REACT_APP_API_URL + 'user_service_queries';
      axios.post(URL, {
          input: userInput,
          is_mobile: true,
          selected_search_result: service,
          rejected_search_results: suggestions
        })
        .then(res => {});
    }
  }

  var serviceError;
  if (errorMessage && service != '') { 
    serviceError = (<div className='error-message border-0 pointer'>
      <p className='bold'>Service not found.</p>
    </div>);
  } else if (errorMessage)  { 
    serviceError = null;
    setErrorMessage(false);
  }

  var SearchInput = () => {
    return (
      <form className='search-page__service-input' onSubmit={ e => searchService(e)}>
        <Link className='search-page__back-arrow'to={
                {     
                  pathname: '/'
                 }
                }> 
        </Link>
        <Autocomplete
          ref={inputRef}
          getItemValue={(item) => item.display_name}
          items={services}
          shouldItemRender={(item, value) => item.display_name.toLowerCase().indexOf(value.toLowerCase()) > -1}
          renderItem={(item, isHighlighted) =>
            <Link key={item.cpt_code} to={
              {     
                pathname: '/service_search',
                state: {service: item.cpt_code}
               }
              }>
              <ServiceItem key={item.cpt_code} service={item} showArrow={false} showUnderline={false} isHighlighted={isHighlighted} classes="service-serch-list-item"/>
            </Link>
          }
          inputProps={{
            'placeholder': "Find your health service",
            'name': "service" }}
          value={service}
          onChange={(e) => handleChange(e.target.value)}
          onMenuVisibilityChange={isOpen => isOpen == false ? createUserServiceQuery() : null}
          onSelect={(val) => handleSelect(val)}
          menuStyle={{
            borderRadius: '0px 0px 4px 4px',
            boxShadow: '0 0px 6px rgba(0, 0, 0, 0.1)',
            background: '#FFFFFF',
            top: '42px',
            right: '0',
            left: '0',
            overflow: 'auto',
            maxHeight: '320px',
            zIndex: '1',
            padding:'16px'
          }}
        />
        <img src={search} width="18" height="18" className='search-icon' />
      </form>
    )
  }

  var MostSearchedServicesSection = () => {
    let mostSearched = mostSearchedServices.map(service => <Link key={service.cpt_code} to={
      {     
        pathname: '/service_search',
        state: {service: service.cpt_code}
       }
      }> <ServiceItem classes="padding-adjustment" service={service} key={service.cpt_code} showArrow={true} showUnderline={true} />
      </Link>);
    return (
      <div>
        <p className='search-page__most-searched-title'>Most Searched Services</p>
        <ul className='search-page__most-searched-list'>
          {mostSearched}
        </ul>
      </div>
    );
  }

  var ServiceGroupsSection = () => {
    let groups = serviceGroups.map(group => <Link key={group} to={
                                              {     
                                                pathname: '/service_group',
                                                state: {group: group}
                                               }
                                              }> 
                                              <p className='search-page__service-group-item'>{group}</p>
                                            </Link>)
    return (
      <div>
        <p className='search-page__service-groups-text'>Are you looking for...</p>
        <ul className='search-page__service-groups-list'>
          {groups}
        </ul>
      </div>
    )
  }

  if ( loading) {
    return (<Loading />);
  } else {
    return (
      <div className='search-page'>
        {SearchInput()}
        {serviceError}
        {showMostSearched? MostSearchedServicesSection() : null}
        {showMostSearched? null : ServiceGroupsSection()}
      </div>
    );
  }
}

export default Search
