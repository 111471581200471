import ToggleSwitch from "../../components/elements/ToggleSwitch";
import SettingsSideBar from "./SettingsSideBar";
import Button from "../../components/elements/Button";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";

function Notifications(props) {
  const location = useLocation();
  const history = useHistory();
  const [userId, setUserId] = useState(
    location.state ? location.state.userId : null
  );
  const [smallScreen, setSmallScreen] = useState(false);
  const { token } = useToken();
  const [userNotificationPreferences, setUserNotificationPreferences] =
    useState({
      appointment_text: false,
      appointment_email: false,
      appointment_secondary_email: false,
      billing_text: false,
      billing_email: false,
      billing_secondary_email: false,
      marketing_text: false,
      marketing_email: false,
      marketing_secondary_email: false,
      allow_location_services: false,
    });

  useEffect(() => {
    getUserNotificationPreferences();
  }, [userId]);

  useEffect(() => {
    getUserId();
  }, []);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  function getUserId() {
    if (userId == null) {
      const URL = process.env.REACT_APP_DEVISE_API_URL + "users/users";
      axios
        .get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setUserId(res.data.data.id);
          } else {
            history.push({
              pathname: "/sign_in",
            });
          }
        })
        .catch(function (error) {
          history.push({
            pathname: "/sign_in",
          });
        });
    }
  }

  function getUserNotificationPreferences() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_notification_preferences/" +
      userId;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          var preferences = res.data.data.attributes;
          setUserNotificationPreferences({
            ...userNotificationPreferences,
            appointment_text: preferences.appointment_text,
            appointment_email: preferences.appointment_email,
            appointment_secondary_email:
              preferences.appointment_secondary_email,
            billing_text: preferences.billing_text,
            billing_email: preferences.billing_email,
            billing_secondary_email: preferences.billing_secondary_email,
            marketing_text: preferences.marketing_text,
            marketing_email: preferences.marketing_email,
            marketing_secondary_email: preferences.marketing_secondary_email,
            allow_location_services: preferences.allow_location_services,
          });
        } else {
          // Error
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function updateUserNotificationPreferences(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_notification_preferences/" +
      userId;
    axios
      .patch(
        URL,
        { user_notification_preference: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          // This is what should happen
        } else {
          // Error
          console.log(res);
        }
      })
      .catch(function (error) {
        // Error
        console.log(error.response);
      });
  }

  function handleChange(e) {
    console.log(e.target.checked);

    setUserNotificationPreferences({
      ...userNotificationPreferences,
      [e.target.name]: e.target.checked,
    });

    var updateRequest = { [e.target.name]: e.target.checked };

    updateUserNotificationPreferences(updateRequest);
  }

  const NotificationsPage = () => {
    return (
      <div className="settings__notifications settings-page">
        <Link to={{ pathname: "/settings" }} className="settings__back-button">
          <p className="body-2 pointer">
            <span className="back-arrow" /> SETTINGS
          </p>
        </Link>
        <p className="settings__page-title">Notifications</p>
        <div className="settings__page-section">
          <p className="settings__subtitle">Appointments</p>
          {!smallScreen && (
            <p className="settings__description">
              Communicate with me about appointments using the following.
            </p>
          )}

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Text Message"
              name="appointment_text"
              checked={userNotificationPreferences.appointment_text}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Work Email"
              name="appointment_email"
              checked={userNotificationPreferences.appointment_email}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Personal Email"
              name="appointment_secondary_email"
              checked={userNotificationPreferences.appointment_secondary_email}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="settings__page-section">
          <p className="settings__subtitle">Billing</p>
          {!smallScreen && (
            <p className="settings__description">
              Communicate with me about bills using the following.
            </p>
          )}

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Text Message"
              name="billing_text"
              checked={userNotificationPreferences.billing_text}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Work Email"
              name="billing_email"
              checked={userNotificationPreferences.billing_email}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Personal Email"
              name="billing_secondary_email"
              checked={userNotificationPreferences.billing_secondary_email}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="settings__page-section">
          <p className="settings__subtitle">Handl updates</p>
          {!smallScreen && (
            <p className="settings__description">
              We’re adding new features every month. Stay up to date with what
              you can do here.
            </p>
          )}

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Text Message"
              name="marketing_text"
              checked={userNotificationPreferences.marketing_text}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Work Email"
              name="marketing_email"
              checked={userNotificationPreferences.marketing_email}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="settings__notification-option">
            <ToggleSwitch
              label="Personal Email"
              name="marketing_secondary_email"
              checked={userNotificationPreferences.marketing_secondary_email}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <SidebarLayout
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses="settings__side-bar"
      content={NotificationsPage()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default Notifications;
