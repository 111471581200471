import React from 'react'
import Button from '../../components/elements/Button';


function LetsGo (props) {
    return (
        <>
         <p className='body-2 pointer' onClick={()=>{props.switchPage('landing'); props.setProgressPercentage(0)}}><span className='back-arrow' /> BACK</p>
        <h2 className='onboarding__landing-header-secondary' >Let’s get to know you more!</h2>
        <div className='onboarding__textbox-primary'>
        <p className='body-1'>To give you the best price estimates and service, we need a few things.</p>

        <ul className='onboarding__bullet-list'>
            <li><p className='body-1'>Contact information and location</p></li>
            <li><p className='body-1'>Insurance plan details</p></li>
            <li><p className='body-1'>Communication preferences</p></li>
        </ul>
        <p className='body-1 landing__final-p'>Ready to get started? This will take less than 5 min!</p>
        <Button onClick={()=>{props.switchPage('stepOne'); props.setProgressPercentage(20)}}className='onboarding__button' label="Let's Go" active="true" class="long-btn filled"/>

        </div>
        </>
       
    )

}

export default LetsGo