import React from 'react'
import shop from "../../assets/icons/onboarding/shop-search.svg"
import price from "../../assets/icons/onboarding/price.svg"
import book from "../../assets/icons/onboarding/book-service.svg"
import Button from '../../components/elements/Button';


function Landing(props) {
    const content = [
        {
            "title": "Shop for care",
            "text": "Compare prices of nearby services and choose the best option for you.",
            "icon": shop
        },
        {
            "title": "Lock in your price",
            "text": "We'll work with your chosen provider to lock in the price of the service you've selected so there are no surprises.",
            "icon": price
        },
        {
            "title": "Book your service",
            "text": "The Concierge will book your appointment based on your preferences.",
            "icon": book
        }

    ]

    return (<div>
        <h2 className='onboarding__landing-header'>At Handl Health, you get concierge care.</h2>
       <div className='onboarding__content'>
       <div className='onboarding__container-col'>
            {content.map((item, index)=> (
                <div key={index} className='onboarding__container-row'>
                <img className='onboarding__container-icon' src={item.icon} alt='icon'/>
                <div className='onboarding__container-textbox'>
                    <p className='onboarding__container-title'>{item.title}</p>
                    <p className='onboarding__container-text'>{item.text}</p>
                </div>
                 </div>

            ))}
            
        </div>
        <Button className='onboarding__button' onClick={()=>props.switchPage('letsGo')} label="Start onboarding" active="true" class="long-btn filled"/>
        
        
       </div>
        </div>
    )
}

export default Landing;